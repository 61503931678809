import { useState } from "react";
import { Button, Input, showToast } from "@attentive-platform/stem-ui";
import { FormContainer } from "../../styled";
import { useChangePassword } from "../../Utils/services";
import { EmailInput } from "../../uicomponents";
import { isValidEmail } from "../../Utils/HelperFunctions";

const DEFAULT_STATE = { email: "", password: "" };

export const ChangePassword = () => {
  const [formData, setFormData] = useState(DEFAULT_STATE);
  const { mutate, loading } = useChangePassword();

  const onSubmitHandler = () => {
    const data = { email: formData.email, new_password: formData.password };
    mutate(
      { data },
      {
        onSuccess: () => {
          showToast("Password changed successfully!");
          setFormData(DEFAULT_STATE);
        },
        onError: (error) => {
          showToast(error, "error");
        },
      }
    );
  };

  const onChangeHandler = (key, value) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const disabledCta =
    !formData.email || !formData.password || !isValidEmail(formData.email);

  return (
    <FormContainer>
      <div>
        <EmailInput
          value={formData.email}
          onChange={onChangeHandler}
          name='cp-page-email'
        />
      </div>
      <div>
        <Input
          name='cp-page-password'
          color='secondary'
          size='medium'
          fullWidth
          placeholder='New password'
          value={formData.password}
          onChange={(e) => onChangeHandler("password", e.target.value)}
        />
      </div>
      <div>
        <Button
          disabled={disabledCta}
          loading={loading}
          color='secondary'
          onClick={onSubmitHandler}
        >
          Change password
        </Button>
      </div>
    </FormContainer>
  );
};
