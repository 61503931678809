import React, { useState } from "react";

import GeneratePortfolio from "../GeneratePortfolio";
import PortfolioReviewAction from "../PortfolioReviewAction";

import { STATUS_ENUM } from "../../../Constants/Constants";

const Action = ({ row, refetchData }) => {
  const [show, setShow] = useState(false);
  const showExecuteAction =
    row.original.is_draft_portfolio &&
    row.original.status === STATUS_ENUM.TO_DO;
  const handleLinkClick = () => {
    setShow(true);
  };
  return (
    <>
      {showExecuteAction ? (
        <PortfolioReviewAction row={row} refetchData={refetchData} />
      ) : (
        <div>
          <div
            onClick={handleLinkClick}
            className=" text-blue-500 font-medium underline  cursor-pointer "
          >
            Generate Portfolio
          </div>
          <GeneratePortfolio show={show} setShow={setShow} />
        </div>
      )}
    </>
  );
};

export default Action;
