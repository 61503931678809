import React, { useState } from "react";
import { Alert } from "./Alert";
import useFormStatus from "../Hooks/useFormStatus";
import EnterpriseConversionForm from "./EnterpriseConversionForm";
import { fetchApiData, interpolate } from "../Utils/ApiCalls";
import { GET_ENTERPRISE_DETAILS } from "../urls";
import LoaderCircle from "./LoaderCircle";

const UpdateContract = () => {
  const [email, setEmail] = useState("");
  const [showEnterpriseForm, setShowEnterpriseForm] = useState(false);

  // Enterprise data
  const [enterpriseAttributes, setEnterpriseAttributes] = useState();
  const [billingDetails, setBillingDetails] = useState();
  const [enterpriseContract, setEnterpriseContract] = useState();
  const [platformAccess, setPlatformAccess] = useState();
  const [companyId, setCompanyId] = useState();
  const [loading, setLoading] = useState(false);

  const { successMessage, errorMessage, handleApiResult } = useFormStatus();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const encodedMail = encodeURIComponent(email.toLowerCase());
    const url = interpolate(GET_ENTERPRISE_DETAILS, [encodedMail]);
    const response = await fetchApiData({
      endpoint: url,
      apiRequest: "GET",
    });

    const data = response?.data;

    handleApiResult(response);

    if (response.error === null) {
      setCompanyId(data?.id);
      setEnterpriseAttributes(data?.enterprise_attributes);
      setEnterpriseContract(data?.enterprise_contract);
      setBillingDetails(data?.billing_details);
      setPlatformAccess(data?.platform_access);
      setShowEnterpriseForm(true);
    }
    setLoading(false);
  };

  return (
    <div className="w-100">
      <Alert errorMessage={errorMessage} successMessage={successMessage} />
      <form onSubmit={handleSubmit} className="w-100 mb-4">
        <div className="input-group mb-3">
          <input
            type="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="form-control form-control-lg"
            placeholder="Email"
            aria-label="Email"
          />
        </div>
        <div className="button">
          <button
            type="submit"
            className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            Next
            {loading && <LoaderCircle />}
          </button>
        </div>
      </form>
      {showEnterpriseForm && (
        <EnterpriseConversionForm
          enterpriseAttributes={enterpriseAttributes}
          billingDetails={billingDetails}
          enterpriseContract={enterpriseContract}
          platformAccess={platformAccess}
          email={email}
          companyId={companyId}
          updateEnterprise
        />
      )}
    </div>
  );
};

export default UpdateContract;
