import { DatePicker, showToast } from "@attentive-platform/stem-ui";
import React, { useState } from "react";
import { fetchApiData, interpolate } from "../../../Utils/ApiCalls";
import { PORTFOLIO_UPDATE } from "../../../urls";

const Tat = ({ row }) => {
  const [value, setValue] = useState(row.values.tat);
  const handleChange = async (date) => {
    const url = interpolate(PORTFOLIO_UPDATE, [row.original.id]);
    const { error } = await fetchApiData({
      endpoint: url,
      apiRequest: "PATCH",
      data: {
        tat: date
          .toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$1-$2"),
      },
    });
    if (error) {
      showToast(error, "error");
      return;
    }
    setValue(date);
    showToast("Executed on updated successfully", "success");
  };
  return (
    <div className="w-44">
      {" "}
      <DatePicker
        inputProps={{ size: "small" }}
        color="primary"
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export default Tat;
