import Action from "./Columns/Action";
import ExecutedOn from "./Columns/ExecutedOn";
import PartialDelivery from "./Columns/PartialDelivery";
import PortfolioSheet from "./Columns/PortfolioSheet";
import Priority from "./Columns/Priority";
import Remark from "./Columns/Remark";
import TakeOff from "./Columns/TakeOff";
import Tat from "./Columns/Tat";
import UpdateStatus from "./Columns/UpdateStatus";

export const getPortfolioColumns = (refetchData) => {
  const columns = [
    {
      Header: "Portfolio Name",
      accessor: "portfolio_name",
      align: "center",
      isSticky: true,

      Cell: ({ row }) => {
        return (
          <div className="flex w-40 justify-center">
            {row?.values?.portfolio_name ? row.values.portfolio_name : "-"}
          </div>
        );
      },
    },
    {
      Header: "No. of properties",
      accessor: "no_of_property",
      Cell: ({ row }) => {
        return row?.values?.no_of_property ? (
          <div className="flex justify-center w-40">
            {row.values.no_of_property}
          </div>
        ) : (
          "-"
        );
      },
      align: "center",
      allowColumnConfig: true,
    },

    {
      Header: "Takeoff Type",
      accessor: "takeoff_type",
      Cell: ({ row }) => {
        return <TakeOff row={row} />;
      },
      align: "center",
    },
    {
      Header: "Executed On",
      accessor: "executed_on",
      Cell: ({ row }) => {
        return <ExecutedOn row={row} />;
      },
      align: "center",
    },
    {
      Header: "Received Date",
      accessor: "received_date",
      Cell: ({ row }) => {
        return (
          <div className="flex justify-center w-40">
            {row.values.received_date ? row.values.received_date : "-"}
          </div>
        );
      },
      align: "center",
      allowColumnConfig: true,
    },

    {
      Header: "TAT",
      accessor: "tat",
      Cell: ({ row }) => {
        return <Tat row={row} />;
      },
      align: "center",
      allowColumnConfig: true,
    },
    {
      Header: "Partial Delivery",
      accessor: "partial_delivery",
      Cell: ({ row }) => {
        return <PartialDelivery row={row} />;
      },
      align: "center",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        return <UpdateStatus row={row} />;
      },
      align: "center",
    },
    {
      Header: "Priority",
      accessor: "priority",
      Cell: ({ row }) => {
        return <Priority row={row} />;
      },
      align: "center",
      allowColumnConfig: true,
    },
    {
      Header: "Remark",
      accessor: "remark",
      Cell: ({ row }) => {
        return <Remark row={row} />;
      },
      align: "center",
    },
    {
      Header: "Portfolio Sheet",
      accessor: "portfolio_sheet",
      Cell: ({ row }) => {
        return <PortfolioSheet row={row} />;
      },
      align: "center",
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => {
        return <Action row={row} refetchData={refetchData} />;
      },
      isSticky: true,
    },
  ];
  return columns;
};
