import { Tab, Tabs } from "@attentive-platform/stem-ui";
import { useState } from "react";
import { OPERATIONS_TAB_ENUM } from "../Constants/Constants";
import GenerateDelivery from "./GenerateDelivery";
import CreateFeature from "./CreateFeature";
import Portfolio from "./Porfolio/Portfolio";
const Operations = () => {
  const [value, setValue] = useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="flex bg-[#FAFAFA] h-full   justify-center">
      <div
        style={{ height: "calc(100vh - 9rem)" }}
        className="flex flex-col mt-14  bg-white rounded-lg w-11/12  "
      >
        <div className="flex-col bg-white    px-8 items-center  border-gray-50 ">
          {" "}
          <div className="flex justify-center">
            <Tabs
              value={value}
              textColor="secondary"
              indicatorColor="secondary"
              onChange={handleChange1}
            >
              <Tab
                value={OPERATIONS_TAB_ENUM.GENERATE_DELIVERY_SHEET}
                label="Generate Delivery Sheet"
                className="p-2"
              />
              <Tab
                value={OPERATIONS_TAB_ENUM.CREATE_FEATURE}
                label="Create Feature"
                className="p-2"
              />
              <Tab
                value={OPERATIONS_TAB_ENUM.PORTFOLIOS}
                label="Portfolios"
                className="p-2"
              />
            </Tabs>
          </div>
          <div className="h-[1px] w-11/12 bg-[#BDBDBD] mb-14"></div>
        </div>
        {value === OPERATIONS_TAB_ENUM.GENERATE_DELIVERY_SHEET && (
          <GenerateDelivery />
        )}
        {value === OPERATIONS_TAB_ENUM.CREATE_FEATURE && <CreateFeature />}
        {value === OPERATIONS_TAB_ENUM.PORTFOLIOS && <Portfolio />}
      </div>
    </div>
  );
};
export default Operations;
