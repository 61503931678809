import React from "react";

const DownArrowSelect = () => {
  return (
    <svg
      height="1.5rem"
      width="1.5rem"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9234 8.4751L18.1484 9.7001L11.9984 15.8501L5.84844 9.7251L7.07344 8.5001L11.9984 13.4251L16.9234 8.4751Z"
        fill="#4D4D4D"
      />
    </svg>
  );
};

export default DownArrowSelect;
