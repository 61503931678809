import React, { useState } from "react";
import { LozengeSelect, showToast } from "@attentive-platform/stem-ui";
import { getStatus } from "../../../Utils/HelperFunctions";
import { STATUS_ENUM } from "../../../Constants/Constants";
import { fetchApiData, interpolate } from "../../../Utils/ApiCalls";
import { PORTFOLIO_UPDATE } from "../../../urls";

const UpdateStatus = ({ row }) => {
  const [status, setStatus] = useState(row.values?.status || 1);
  const dropdownItems = Object.values(STATUS_ENUM).map((value) => {
    return {
      value: value,
      name: getStatus(value)[2],
      color: getStatus(value)[0],
    };
  });
  const handleChange = async (e) => {
    try {
      const url = interpolate(PORTFOLIO_UPDATE, [row.original.id]);

      const { error } = await fetchApiData({
        endpoint: url,
        apiRequest: "PATCH",
        data: { status: e.target.value },
      });
      if (error) {
        showToast(error, "error");
        return;
      }
      setStatus(e.target.value);
      showToast("Status updated successfully", "success");
    } catch (e) {}
  };
  return (
    <>
      {" "}
      <LozengeSelect
        dropdownItems={dropdownItems}
        value={status}
        onChange={handleChange}
      />
    </>
  );
};
export default UpdateStatus;
