import React, { useCallback, useEffect, useState } from "react";
import { showToast, Tab, Tabs, Tooltip } from "@attentive-platform/stem-ui";
import { ReactComponent as EyeIcon } from "../Icons/vector.svg";
import { ReactComponent as UndoIcon } from "../Icons/undo.svg";
import { fetchApiData } from "../Utils/ApiCalls";
import { BPT_TAB_ENUM } from "../Constants/Constants";
import useFormStatus from "../Hooks/useFormStatus";
import usePagination from "../Hooks/usePagination";
import Pagination from "./Pagination";
import { Loader } from "./Loader";
import { Alert } from "./Alert";
import { Modal } from "@attentive-platform/stem-ui";
import { GET_VALIDATE_STATUS_REQUESTS, APPROVE_REQUESTS } from "../urls";

const FileDataList = ({ files, takeoffId, deletedFiles, setDeletedFiles }) => {
  const handleDelete = (fileId) => {
    setDeletedFiles((prev) => ({
      ...prev,
      [takeoffId]: [...(prev[takeoffId] || []), fileId],
    }));
  };

  const handleUndo = (fileId) => {
    setDeletedFiles((prev) => ({
      ...prev,
      [takeoffId]: (prev[takeoffId] || []).filter((id) => id !== fileId),
    }));
  };

  const handleFileDownload = (url) => {
    url && window.open(url, "noopener,noreferrer");
  };

  return (
    <div className="w-full">
      {files.map((file, index) => (
        <div
          key={file.id}
          className="flex justify-end border-b items-center p-2"
        >
          {deletedFiles[takeoffId]?.includes(file.id) ? (
            <div
              className="flex justify-end border-b items-center text-center cursor-pointer "
              style={{ color: "#4CBB7F" }}
              onClick={() => handleUndo(file.id)}
            >
              <div className="flex items-center mr-2">
                <UndoIcon />
              </div>
              <div>Reattach file</div>
            </div>
          ) : (
            <>
              <Tooltip
                title={
                  file.url
                    ? "Click to download attachment"
                    : "No file to download"
                }
              >
                <div
                  className={`mr-4 pointer input-text-align ${
                    file.url && `text-blue-500 hover:underline`
                  }`}
                  onClick={() => handleFileDownload(file.url)}
                >
                  {file.name}
                </div>
              </Tooltip>
              <div className="mr-4">
                {file.pages !== null ? `${file.pages} pages` : "N/A"}
              </div>
              <div className="mr-4">
                {(file.size / (1024 * 1024)).toFixed(2)} MB
              </div>
              <div className="">
                <img
                  onClick={() => handleDelete(file.id)}
                  className="delete-icon cursor-pointer"
                  alt="red cross icon"
                  src="https://storage.googleapis.com/falcon-shared-images-front-end/assets/svgs/output/cross-red.svg"
                  style={{ width: "16px", height: "16px" }}
                />
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

const ValidateRequests = () => {
  const [requestsData, setRequestsData] = useState([]);
  const [count, setCount] = useState();
  const [loading, setLoading] = useState(false);

  const { errorMessage, handleApiResult } = useFormStatus();
  const {
    page,
    setPage,
    totalPages,
    pageNumbers,
    recordsPerPage,
    setRecordsPerPage,
  } = usePagination({ count });

  const fetchRequestsData = useCallback(async () => {
    const params = {
      page: page,
      page_size: recordsPerPage,
    };

    setLoading(true);
    const res = await fetchApiData({
      endpoint: GET_VALIDATE_STATUS_REQUESTS,
      apiRequest: "GET",
      params: params,
    });
    handleApiResult(res);
    setLoading(false);
    setRequestsData([]);

    if (res?.data?.results) {
      setRequestsData(res?.data?.results);
      const count = res?.data?.count;
      setCount(count);
    }
  }, [handleApiResult, page, recordsPerPage]);

  useEffect(() => {
    fetchRequestsData();
  }, [fetchRequestsData]);

  // Defining it for future use
  const [value, setValue] = useState(0); // eslint-disable-line no-unused-vars

  return (
    <div className="flex bg-[#FAFAFA] h-full   justify-center">
      <div
        style={{ height: "calc(100vh - 9rem)" }}
        className="flex flex-col mt-14  bg-white rounded-lg w-11/12  "
      >
        <div className="flex-col bg-white flex   px-8 items-center  border-gray-50 ">
          {" "}
          <div className="flex justify-center">
            <Tabs
              value={value}
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab
                value={BPT_TAB_ENUM.VALIDATE_REQUEST}
                label="Large PDFs Takeoffs Approval"
                className="p-2"
              />
            </Tabs>
          </div>
          <div className="h-[1px] w-11/12 bg-[#BDBDBD] mb-14"></div>
        </div>
        {value === BPT_TAB_ENUM.VALIDATE_REQUEST && (
          <div className="px-0 pb-5 justify-center">
            <Alert errorMessage={errorMessage} />

            {loading ? (
              <Loader />
            ) : (
              <div>
                <div className="overflow-x-scroll flex justify-center">
                  <ValidateStatusRequestTable
                    requestsData={requestsData}
                    fetchRequestsData={fetchRequestsData}
                  />
                </div>
                <div className="m-4">
                  <Pagination
                    totalPages={totalPages}
                    setPage={setPage}
                    page={page}
                    pageNumbers={pageNumbers}
                    recordsPerPage={recordsPerPage}
                    setRecordsPerPage={setRecordsPerPage}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ValidateRequests;

const ValidateStatusRequestTable = ({ requestsData, fetchRequestsData }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deletedFiles, setDeletedFiles] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const { successMessage, errorMessage, handleApiResult } = useFormStatus();
  const handleScopeModal = (id) => {
    setOpen(true);
    setSelectedId(id);
  };
  const handleScopeModalClose = () => {
    setOpen(false);
    setSelectedId(null);
  };

  const handleApproveTakeoff = async (id) => {
    const requestBody = {
      request_id: id,
      deleted_files: deletedFiles[id] ? deletedFiles[id] : [],
    };
    const apiData = {
      endpoint: APPROVE_REQUESTS,
    };

    apiData.data = requestBody;
    apiData.apiRequest = "POST";

    const res = await fetchApiData(apiData);
    handleApiResult(res);
    if (res.error == null) {
      showToast("Request approved", "success");
      fetchRequestsData();
    }
    setLoading(false);
  };

  useEffect(() => {}, [open]);

  const colNames = [
    "Request Name",
    "Customer Name",
    "Company",
    "Submitted On",
    "Scope",
    "Attachments",
    "Action",
  ];

  const getClassName = (id) => {
    // Define specific column indexes that should be right-aligned
    const rightAlignedColumns = [3, 4, 5];

    return `border border-slate-200 bg-slate-200 p-2 ${
      rightAlignedColumns.includes(id) ? "text-right" : ""
    }`;
  };
  return loading ? (
    <Loader />
  ) : (
    <table className="table-auto w-max p-4">
      <Alert errorMessage={errorMessage} successMessage={successMessage} />
      <thead className="">
        <tr className="bg-slate-400">
          {colNames?.map((col, index) => {
            return <th className={getClassName(index)}>{col}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {requestsData?.map((data, id) => {
          const defaultClassName = "border border-slate-100 p-2 ";
          return (
            <tr key={id}>
              {data?.request_name !== undefined && (
                <td
                  className={defaultClassName + "text-left"}
                  rowSpan={data?.rowSpanCompany}
                >
                  {data?.request_name}
                </td>
              )}
              {data?.customer_name !== undefined && (
                <td
                  className={defaultClassName + "text-left"}
                  rowSpan={data?.rowSpanCompany}
                >
                  {data?.customer_name}
                </td>
              )}
              {data?.company !== undefined && (
                <td
                  className={defaultClassName + "text-left"}
                  rowSpan={data?.no_of_invoices}
                >
                  {data?.company}
                </td>
              )}

              {data?.submitted_on !== undefined && (
                <td className={defaultClassName + "text-right"}>
                  {data?.submitted_on}
                </td>
              )}
              {data?.scope !== undefined && (
                <td className={defaultClassName + "text-left"}>
                  <EyeIcon
                    onClick={() => handleScopeModal(id)}
                    className="cursor-pointer"
                  />
                  {selectedId === id && (
                    <Modal
                      show={open}
                      heading={<span className="pl-4">Scope</span>}
                      onClose={handleScopeModalClose}
                      content={
                        <div className="flex flex-col gap-2 m-4  w-[500px]">
                          {data.scope}
                        </div>
                      }
                    />
                  )}
                </td>
              )}
              {data?.attachments !== undefined && (
                <td className={defaultClassName + "text-right"}>
                  <FileDataList
                    files={data?.attachments}
                    takeoffId={data?.request_id}
                    deletedFiles={deletedFiles}
                    setDeletedFiles={setDeletedFiles}
                  />
                </td>
              )}

              <td
                className={defaultClassName + "cursor-pointer text-left"}
                onClick={() => handleApproveTakeoff(data?.request_id)}
                rowSpan={data?.no_of_invoices}
                style={{ color: "#4CBB7F" }}
              >
                Approve Takeoff
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
