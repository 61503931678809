import {
  Button,
  DataTable,
  DatePicker,
  Popover,
  Select,
  showToast,
  StyleProvider,
  Typography,
} from "@attentive-platform/stem-ui";
import { useEffect, useMemo, useState } from "react";
import DownArrowSelect from "../../Assets/DownArrowSelect";
import { getCompanyUsageColumns } from "./Header";
import { COMPANY_USAGE_ENDPOINT, EMAIL_COMPANY_USAGE } from "../../urls";
import { fetchApiData } from "../../Utils/ApiCalls";
import {
  COMPANY_STATUS_NEW,
  COMPANY_STATUS_NEW_ENUM,
  COMPANY_TYPE,
} from "../../Constants/Constants";
const DEFAULT_DATA = {
  count: 0,
  results: [],
};
const CompanyUsage = () => {
  const [company, setCompany] = useState([]);
  const [showContractDate, setShowContractDate] = useState(true);
  const [status, setStatus] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date(Date.now()));
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isFetching, setIsFetching] = useState(false);
  const columns = getCompanyUsageColumns();
  const handleCompanyTypeChange = (value) => {
    setCompany(value);
  };

  const handleStatusChange = (value) => {
    setStatus(value);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleChangePageSize = (pageSize) => {
    setPageSize(pageSize);
    setPage(0);
  };

  const [companyUsageData, setCompanyUsageData] = useState(DEFAULT_DATA);

  const [isDownloading, setIsDownloading] = useState(false);

  const formatDate = (date) => {
    return date ? new Date(date).toISOString().split("T")[0] : null;
  };

  const EmailCompanyUsageData = async () => {
    setIsDownloading(true);

    const { data } = await fetchApiData({
      endpoint: EMAIL_COMPANY_USAGE,
      apiRequest: "POST",
      params: {
        ...(status.length > 0 && { status: status }),
        ...(company.length > 0 && { company_type: company }),
        ...(startDate && {
          contract_date_after: formatDate(startDate),
        }),
        ...(endDate && {
          contract_date_before: formatDate(endDate),
        }),
        page: page + 1,
        page_size: pageSize,
      },
    });
    if (data) {
      showToast(data, "success");
    }
    setIsDownloading(false);
  };

  const getCompanyUsageData = async () => {
    setIsFetching(true);
    const { data } = await fetchApiData({
      endpoint: COMPANY_USAGE_ENDPOINT,
      apiRequest: "GET",
      params: {
        ...(status.length > 0 && { status: status }),
        ...(company.length > 0 && { type: company }),
        ...(startDate && {
          contract_date_after: formatDate(startDate),
        }),
        ...(endDate && {
          contract_date_before: formatDate(endDate),
        }),
        page: page + 1,
        page_size: pageSize,
      },
    });

    setIsFetching(false);
    setCompanyUsageData(data || DEFAULT_DATA);
  };
  useEffect(() => {
    getCompanyUsageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, company, page, pageSize, startDate, endDate]);

  const paginationProps = useMemo(
    () => ({
      handleChangePage,
      handleChangePageSize,
      page,
      pageSize,
      hidePagination:
        !isFetching && (companyUsageData?.results || []).length === 0,
    }),
    [page, pageSize, isFetching, companyUsageData]
  );

  return (
    <div className="flex flex-col justify-between p-8 items-start gap-2 self-stretch">
      <div className="flex justify-between items-center self-stretch">
        <div className="flex items-center gap-2">
          <Select
            allowClear
            multiple
            color="secondary"
            items={
              Object.keys(COMPANY_TYPE).map((type) => ({
                value: type,
                label: COMPANY_TYPE[type],
              })) || []
            }
            value={company}
            onChange={handleCompanyTypeChange}
            placeholder="Company Type"
            variant="std-filled"
            className="h-[2.8125rem] w-48"
          />
          <Select
            allowClear
            multiple
            color="secondary"
            items={
              Object.values(COMPANY_STATUS_NEW_ENUM).map((status) => ({
                value: status,
                label: COMPANY_STATUS_NEW[status],
              })) || []
            }
            value={status}
            onChange={handleStatusChange}
            placeholder="Company Status"
            variant="std-filled"
            className="h-[2.8125rem] w-48"
          />
          <Popover
            triggerEvent={"click"}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            content={
              <div className="bg-white w-full p-4">
                {(startDate || endDate) && (
                  <div
                    className="flex justify-end text-sm text-gray-500 cursor-pointer"
                    onClick={() => {
                      setStartDate(null);
                    }}
                  >
                    Clear
                  </div>
                )}
                <div className="inline-flex gap-4">
                  {" "}
                  {showContractDate ? (
                    <div className="w-[144px] h-[34px] bg-[#cdcaca] flex items-center justify-center rounded-sm">
                      <Typography>Contract Start Date</Typography>
                      <div onClick={() => setShowContractDate(false)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M9.99983 8.82208L14.1247 4.69727L15.3032 5.87577L11.1783 10.0006L15.3032 14.1253L14.1247 15.3038L9.99983 11.1791L5.87505 15.3038L4.69653 14.1253L8.82133 10.0006L4.69653 5.87577L5.87505 4.69727L9.99983 8.82208Z"
                            fill="#4D4D4D"
                          />
                        </svg>
                      </div>
                    </div>
                  ) : (
                    <DatePicker
                      value={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                      }}
                      variant="std-filled"
                      placeholder="Select Date"
                      className="w-48"
                      inputProps={{
                        size: "small",
                        placeholder: "Contract Start Date",
                      }}
                    />
                  )}
                  <DatePicker
                    value={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                    }}
                    variant="std-filled"
                    placeholder="Select Date"
                    className="w-48"
                    inputProps={{
                      size: "small",
                      placeholder: "Contract Start Date",
                    }}
                  />
                </div>
              </div>
            }
            direction="bottom"
          >
            <div
              className={`px-3 py-2 flex w-40 justify-between items-center rounded-sm bg-[#EBEBEB]  ${"cursor-pointer "}`}
            >
              <Typography>Date Range</Typography>
              <DownArrowSelect />
            </div>
          </Popover>
        </div>

        <div className="flex justify-end">
          <Button
            color="secondary"
            size="medium"
            variant="contained"
            loading={isDownloading}
            onClick={EmailCompanyUsageData}
          >
            Email report
          </Button>
        </div>
      </div>
      <div className="flex justify-between items-center pt-4 w-full">
        <StyleProvider>
          <DataTable
            rowProps={{ isSelectable: () => false }}
            allowBulkSelect={false}
            isLoading={isFetching}
            data={companyUsageData?.results || []}
            columns={columns}
            options={{
              showFooter: false,
              stickyHeader: true,
            }}
            classes={{
              container: "scrollbar",
            }}
            paginationProps={paginationProps}
            totalCountProps={{
              showTotalCount: true,
              totalRowCount: companyUsageData?.count,
            }}
          />
        </StyleProvider>
      </div>
    </div>
  );
};
export default CompanyUsage;
