import { Input, showToast } from "@attentive-platform/stem-ui";
import React, { useEffect, useState } from "react";
import { PORTFOLIO_UPDATE } from "../../../urls";
import { fetchApiData, interpolate } from "../../../Utils/ApiCalls";

const Priority = ({ row }) => {
  const [value, setValue] = useState(row.original.priority);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    setValue(row.values.priority);
  }, [row.values.priority]);

  const handleBlur = async () => {
    if (value === row.values.priority) return;

    const url = interpolate(PORTFOLIO_UPDATE, [row.original.id]);
    const { error } = await fetchApiData({
      endpoint: url,
      apiRequest: "PATCH",
      data: { priority: value },
    });

    if (error) {
      showToast(error, "error");
      setValue(row.values.priority);
    } else {
      showToast("Priority updated successfully", "success");
    }
  };

  return (
    <Input
      size="small"
      className="w-12"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default Priority;
