import {
  Button,
  Divider,
  Select,
  Popover,
  showToast,
  Input,
  StyleProvider,
} from "@attentive-platform/stem-ui";
import { ReactComponent as LeftCard } from "../Icons/create-feature-card.svg";
import { useState } from "react";
import { GEOMETRY_TYPES, GROUP_TYPE } from "../Constants/Constants";
import { ReactComponent as FillColor } from "../Icons/fill-color.svg";
import { ChromePicker } from "react-color";
import { fetchApiData } from "../Utils/ApiCalls";
import { CREATE_FEATURE_ENDPOINT } from "../urls";
import InputField from "./InputField";

const initialFeatureDetails = {
  name: "",
  description: "",
  geometry_type: "",
  group_type: "",
  layer_min_qc_time: "",
  layer_first_range_qc_time: "",
  layer_second_range_qc_time: "",
  layer_max_qc_time: "",
  layer_min_qa_time: "",
  layer_first_range_qa_time: "",
  layer_second_range_qa_time: "",
  layer_max_qa_time: "",
  priority: "",
  is_overlapping_allowed: "",
  layer_group_name: "",
  color: "",
  z_index: "",
};

const requiredFields = [
  "name",
  "description",
  "geometry_type",
  "group_type",
  "layer_min_qc_time",
  "layer_first_range_qc_time",
  "layer_second_range_qc_time",
  "layer_max_qc_time",
  "layer_min_qa_time",
  "layer_first_range_qa_time",
  "layer_second_range_qa_time",
  "layer_max_qa_time",
  "priority",
  "is_overlapping_allowed",
  "layer_group_name",
  "color",
  "z_index",
];

const validateFields = (fields) => {
  const errors = {};
  requiredFields.forEach((field) => {
    if (
      fields[field] === undefined ||
      fields[field] === null ||
      fields[field] === ""
    ) {
      errors[field] = `${field.replace(/_/g, " ")} is required`;
    }
  });
  return errors;
};

const CreateFeature = () => {
  const [loading, setLoading] = useState(false);
  const [featureDetails, setFeatureDetails] = useState(initialFeatureDetails);
  const [errorMessages, setErrorMessages] = useState({});

  const handleChange = (key, value) => {
    setFeatureDetails((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleCreateFeature = async () => {
    const errors = validateFields(featureDetails);
    setErrorMessages(errors);
    if (Object.keys(errors).length !== 0) return;

    setLoading(true);
    const { error } = await fetchApiData({
      endpoint: CREATE_FEATURE_ENDPOINT,
      apiRequest: "POST",
      data: featureDetails,
    });
    setLoading(false);
    if (error) {
      showToast(error, "error");
      return;
    }
    setFeatureDetails(initialFeatureDetails);
    showToast("Feature created successfully", "success");
  };

  return (
    <StyleProvider theme="attentive-internal">
      <div className="flex flex-col items-center">
        <div className="flex w-11/12">
          <div>
            <LeftCard />
          </div>
          <Divider className="pl-6 pr-12" orientation="vertical" />
          <div className="flex flex-col gap-6 pl-6">
            <div className="flex gap-6">
              <InputField
                label="Feature Name"
                keyName="name"
                placeholder="Add Feature name"
                value={featureDetails.name}
                onChange={handleChange}
                error={errorMessages.name}
              />
              <InputField
                label="Feature Description"
                keyName="description"
                placeholder="Add Feature Description"
                value={featureDetails.description}
                onChange={handleChange}
                error={errorMessages.description}
              />
              <div className="flex flex-col">
                <span className="text-sm font-medium">Geometry Type:</span>
                <Select
                  allowClear
                  className="w-72 pr-0 mr-0"
                  buttonStyle={{ height: "37px" }}
                  size="small"
                  items={GEOMETRY_TYPES}
                  onChange={(value) => handleChange("geometry_type", value)}
                  value={featureDetails.geometry_type}
                  placeholder="Select"
                />
                {errorMessages.geometry_type && (
                  <span className="text-red-500 text-xs">
                    {errorMessages.geometry_type}
                  </span>
                )}
              </div>
            </div>
            <div className="flex gap-6">
              <div className="flex flex-col">
                <span className="text-sm font-medium">Group Type:</span>
                <Select
                  allowClear
                  className="w-72 pr-0 mr-0"
                  buttonStyle={{ height: "37px" }}
                  size="small"
                  items={GROUP_TYPE}
                  onChange={(value) => handleChange("group_type", value)}
                  value={featureDetails.group_type}
                  placeholder="Select"
                />
                {errorMessages.group_type && (
                  <span className="text-red-500 text-xs">
                    {errorMessages.group_type}
                  </span>
                )}
              </div>
              <InputField
                label="Layer min QC time (In Seconds)"
                keyName="layer_min_qc_time"
                type="number"
                placeholder="Add Min QC Time"
                value={featureDetails.layer_min_qc_time}
                onChange={handleChange}
                error={errorMessages.layer_min_qc_time}
              />
              <InputField
                label="Layer First range QC Time (In Seconds)"
                keyName="layer_first_range_qc_time"
                type="number"
                placeholder="Add First range QC Time"
                value={featureDetails.layer_first_range_qc_time}
                onChange={handleChange}
                error={errorMessages.layer_first_range_qc_time}
              />
            </div>
            <div className="flex gap-6">
              <InputField
                label="Layer second range QC Time (In Seconds)"
                keyName="layer_second_range_qc_time"
                type="number"
                placeholder="Add Second range QC Time"
                value={featureDetails.layer_second_range_qc_time}
                onChange={handleChange}
                error={errorMessages.layer_second_range_qc_time}
              />
              <InputField
                label="Layer Max QC Time (In Seconds)"
                keyName="layer_max_qc_time"
                type="number"
                placeholder="Add Max QC Time"
                value={featureDetails.layer_max_qc_time}
                onChange={handleChange}
                error={errorMessages.layer_max_qc_time}
              />
              <InputField
                label="Layer Min QA Time (In Seconds)"
                keyName="layer_min_qa_time"
                type="number"
                placeholder="Add Min QA Time"
                value={featureDetails.layer_min_qa_time}
                onChange={handleChange}
                error={errorMessages.layer_min_qa_time}
              />
            </div>
            <div className="flex gap-6">
              <InputField
                label="Layer First Range QA Time (In Seconds)"
                keyName="layer_first_range_qa_time"
                type="number"
                placeholder="Add First Range QA Time"
                value={featureDetails.layer_first_range_qa_time}
                onChange={handleChange}
                error={errorMessages.layer_first_range_qa_time}
              />
              <InputField
                label="Layer second range QA Time (In Seconds)"
                keyName="layer_second_range_qa_time"
                type="number"
                placeholder="Add Second Range QA Time"
                value={featureDetails.layer_second_range_qa_time}
                onChange={handleChange}
                error={errorMessages.layer_second_range_qa_time}
              />
              <InputField
                label="Layer Max QA Time (In Seconds)"
                keyName="layer_max_qa_time"
                type="number"
                placeholder="Add Max QA Time"
                value={featureDetails.layer_max_qa_time}
                onChange={handleChange}
                error={errorMessages.layer_max_qa_time}
              />
            </div>
            <div className="flex gap-6">
              <InputField
                label="Priority"
                keyName="priority"
                type="number"
                placeholder="Add Priority"
                value={featureDetails.priority}
                onChange={handleChange}
                error={errorMessages.priority}
              />
              <div className="flex flex-col">
                <span className="text-sm font-medium">
                  Is Overlapping Allowed:
                </span>
                <Select
                  allowClear
                  className="w-72"
                  buttonStyle={{ height: "37px" }}
                  size="small"
                  items={[
                    { value: true, label: "True" },
                    { value: false, label: "False" },
                  ]}
                  onChange={(value) =>
                    handleChange("is_overlapping_allowed", value)
                  }
                  value={featureDetails.is_overlapping_allowed}
                  placeholder="Select"
                />
                {errorMessages.is_overlapping_allowed && (
                  <span className="text-red-500 text-xs">
                    {errorMessages.is_overlapping_allowed}
                  </span>
                )}
              </div>
              <InputField
                label="Layer Group name"
                keyName="layer_group_name"
                placeholder="Add layer group name"
                value={featureDetails.layer_group_name}
                onChange={handleChange}
                error={errorMessages.layer_group_name}
              />
            </div>
            <div className="flex gap-6">
              <div className="flex flex-col">
                <span className="text-sm font-medium">Color:</span>
                <Popover
                  content={
                    <ChromePicker
                      color={featureDetails.color}
                      onChange={(color) => handleChange("color", color.hex)}
                      disableAlpha={true}
                    />
                  }
                  triggerEvent="click"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  transformOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                  <div>
                    <Input
                      endIcons={[{ icon: () => <FillColor /> }]}
                      placeholder="color"
                      size="small"
                      className="w-[27rem]"
                      value={featureDetails.color}
                    />
                  </div>
                </Popover>
                {errorMessages.color && (
                  <span className="text-red-500 text-xs">
                    {errorMessages.color}
                  </span>
                )}
              </div>
              <InputField
                label="Z index"
                keyName="z_index"
                type="number"
                placeholder="Add Z index"
                value={featureDetails.z_index}
                onChange={handleChange}
                error={errorMessages.z_index}
              />
            </div>
          </div>
        </div>
        <div className="w-full fixed left-0 bottom-0 bg-white shadow-inner p-2">
          <div className="flex gap-4 mt-2 items-end justify-end bg-white">
            <Button
              className="mr-10"
              variant="contained"
              size="small"
              style={{ width: "9.375rem" }}
              onClick={handleCreateFeature}
              loading={loading}
            >
              Create Feature
            </Button>
          </div>
        </div>
      </div>
    </StyleProvider>
  );
};

export default CreateFeature;
