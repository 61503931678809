import React from "react";
import { downloadFileFromUrl } from "../../../Utils/HelperFunctions";

const PortfolioSheet = ({ row }) => {
  const handleLinkClick = () => {
    // window.open(`/batch/${row.id}`, "_blank");
    downloadFileFromUrl(row.original.file_url);
  };

  return (
    <div
      onClick={handleLinkClick}
      className=" text-blue-500 font-medium underline  cursor-pointer "
    >
      Link
    </div>
  );
};

export default PortfolioSheet;
