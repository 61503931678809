import React, { useEffect, useState } from "react";
import {
  AERIAL_TANSFER_TYPE_ENUM,
  BPT_TANSFER_TYPE_ENUM,
  REQUEST_TYPE_ENUM,
  TRANSFER_TYPE,
  TRANSFER_TYPE_ENUM,
} from "../Constants/Constants";
import { fetchApiData, interpolate } from "../Utils/ApiCalls";
import {
  BULK_DUPLICATE,
  CHANGE_OWNER,
  FMS_UPLOAD,
  TRANSFER_REQUEST,
} from "../urls";
import useFormStatus from "../Hooks/useFormStatus";
import { ReactComponent as Arrow } from "../Icons/accordian-arrow.svg";
import LoaderCircle from "./LoaderCircle";

const CopyTransfer = () => {
  const [requestType, setRequestType] = useState();
  const [bptTransferType, setBptTransferType] = useState();
  const [aerialTransferType, setAerialTransferType] = useState();
  const [requestIds, setRequestIds] = useState([]);
  const [customerEmail, setCustomerEmail] = useState("");

  const [isFlockTransfer, setIsFlockTransfer] = useState(false);
  const [transferType, setTransferType] = useState(
    TRANSFER_TYPE_ENUM.PORTFOLIO_TRANSFER
  );
  const [proceedBtn, setProceedBtn] = useState(true);
  const [featureReportId, setFeatureReportId] = useState();
  const [portfolioName, setPortfolioName] = useState();
  const [folderName, setFolderName] = useState();
  const [perAcreCost, setPerAcreCost] = useState();
  const [folderIds, setFolderIds] = useState();
  const [retry, setRetry] = useState(false);
  const [remeasurementRequest, setRemeasurementRequest] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const { successMessage, errorMessage, handleApiResult } = useFormStatus();

  useEffect(() => {
    if (aerialTransferType === AERIAL_TANSFER_TYPE_ENUM.NOT_COPY_AND_CHARGE)
      setProceedBtn(true);
    else {
      setProceedBtn(false);
    }
    if (
      aerialTransferType !== AERIAL_TANSFER_TYPE_ENUM.NOT_COPY_AND_CHARGE ||
      transferType === TRANSFER_TYPE_ENUM.NON_PORTFOLIO
    )
      setIsFlockTransfer(false);
  }, [transferType, isFlockTransfer, aerialTransferType]);

  useEffect(() => {
    clearAllFields(false);
  }, [requestType]);

  const isBPT = requestType === REQUEST_TYPE_ENUM.CONSTRUCTION;

  const clearAllFields = (apiCall = true) => {
    apiCall && setRequestType(undefined);
    setBptTransferType(undefined);
    setAerialTransferType(undefined);
    setRequestIds([]);
    setIsFlockTransfer(false);
    setTransferType(TRANSFER_TYPE_ENUM.PORTFOLIO_TRANSFER);
    setProceedBtn(true);
    setFeatureReportId(undefined);
    setPortfolioName(undefined);
    setFolderName(undefined);
    setPerAcreCost(undefined);
    setFolderIds([]);
    setRetry(false);
    setRemeasurementRequest(false);
    setEmail("");
    setCustomerEmail("");
  };

  const handleFlockToFalconTransfer = async () => {
    let requestIdsList = requestIds;
    if (typeof requestIds === "string") {
      requestIdsList = cleanEnteredValues(requestIds);
    }
    const data = {
      orchestrator_ids: requestIdsList,
      user_email: customerEmail.toLowerCase(),
      portfolio_name: portfolioName,
      feature_report: featureReportId,
      folder_name: folderName,
      retry: retry,
      per_acre_cost: perAcreCost,
      is_remeasurement_request: remeasurementRequest,
    };
    setLoading(true);
    const response = await fetchApiData({
      endpoint: FMS_UPLOAD,
      data: data,
    });
    handleApiResult(response);

    setLoading(false);

    if (response.error === null) clearAllFields();
  };

  const handleNotCopyAndCharge = async ({
    isPortfolioTransfer = false,
    isBPT,
  }) => {
    let requestIdsList = requestIds;
    if (typeof requestIds === "string") {
      requestIdsList = cleanEnteredValues(requestIds);
    }
    const data = {
      request_ids: requestIdsList,
      user_email: customerEmail.toLowerCase(),
      per_acre_cost: perAcreCost,
      is_remeasurement_request: remeasurementRequest,
    };
    if (isPortfolioTransfer) {
      data.portfolio_name = portfolioName;
      data.folder_name = folderName;
    }
    setLoading(true);
    const response = await fetchApiData({
      endpoint: interpolate(TRANSFER_REQUEST, [
        isBPT ? "/construction/fx" : "/admin/requests",
      ]),

      data: data,
    });
    handleApiResult(response);

    setLoading(false);

    if (response.error === null) clearAllFields();
  };

  const handleCopyAndNotCharge = async ({ isBPT }) => {
    let requestIdsList = requestIds;
    let folderIdsList = folderIds;
    if (typeof requestIds === "string") {
      requestIdsList = cleanEnteredValues(requestIds);
    }
    if (typeof folderIds === "string") {
      folderIdsList = cleanEnteredValues(folderIds);
    }
    const data = {
      request_ids: requestIdsList,
      user_email: customerEmail.toLowerCase(),
      folder_ids: folderIdsList,
      charge_user: false,
    };
    setLoading(true);
    const response = await fetchApiData({
      endpoint: interpolate(BULK_DUPLICATE, [
        isBPT ? "/construction/fx" : "/admin/requests",
      ]),
      data: data,
    });
    handleApiResult(response);

    setLoading(false);

    if (response.error === null) clearAllFields();
  };

  const handleNotCopyAndNotCharge = async ({ isBPT }) => {
    let requestIdsList = requestIds;
    if (typeof requestIds === "string") {
      requestIdsList = cleanEnteredValues(requestIds);
    }

    const data = {
      request_ids: requestIdsList,
      email: email,
    };
    setLoading(true);
    const response = await fetchApiData({
      endpoint: interpolate(CHANGE_OWNER, [
        isBPT ? "/construction/fx" : "/admin/requests",
      ]),
      data: data,
    });
    handleApiResult(response);
    setLoading(false);
    if (response.error === null) clearAllFields();
  };

  const cleanEnteredValues = (ids) => {
    const cleanedValues = ids
      .split(",")
      .map((value) => value.trim())
      .filter((value) => value !== "");

    // Join the cleaned values with commas
    const cleanedString = cleanedValues.join(",");
    const individualValues = cleanedString.split(",");
    return individualValues;
  };

  const handleTransfer = () => {
    if (typeof requestIds === "string") {
      cleanEnteredValues(requestIds);
    }
    if (typeof folderIds === "string") {
      cleanEnteredValues(folderIds);
    }

    if (requestType === REQUEST_TYPE_ENUM.AERIAL) {
      // CASE 1: Not Copy + Charge
      if (aerialTransferType === AERIAL_TANSFER_TYPE_ENUM.NOT_COPY_AND_CHARGE) {
        if (transferType === TRANSFER_TYPE_ENUM.PORTFOLIO_TRANSFER) {
          // CASE 1.1 - Aerial transfer request + Portfolio transfer + Flock to Falcon transfer
          if (isFlockTransfer) {
            handleFlockToFalconTransfer();
          }
          // CASE 1.2 - Aerial transfer request + Portfolio transfer
          else {
            handleNotCopyAndCharge({ isPortfolioTransfer: true });
          }
        }
        // CASE 1.3 - Aerial transfer + Non Portfolio
        else {
          handleNotCopyAndCharge({});
        }
      }
      // CASE 2: Copy + Not Charge
      else if (
        aerialTransferType === AERIAL_TANSFER_TYPE_ENUM.COPY_AND_NOT_CHARGE
      ) {
        handleCopyAndNotCharge({});
      }
      // CASE 3: Not Copy + Not Charge
      else if (
        aerialTransferType === AERIAL_TANSFER_TYPE_ENUM.NOT_COPY_AND_NOT_CHARGE
      ) {
        handleNotCopyAndNotCharge({});
      }
    } else if (requestType === REQUEST_TYPE_ENUM.CONSTRUCTION) {
      if (bptTransferType === BPT_TANSFER_TYPE_ENUM.NOT_COPY_AND_CHARGE) {
        handleNotCopyAndCharge({ isBPT });
      } else if (
        bptTransferType === BPT_TANSFER_TYPE_ENUM.COPY_AND_NOT_CHARGE
      ) {
        handleCopyAndNotCharge({ isBPT });
      } else if (
        bptTransferType === BPT_TANSFER_TYPE_ENUM.NOT_COPY_AND_NOT_CHARGE
      ) {
        handleNotCopyAndNotCharge({ isBPT });
      }
    }
  };

  return (
    <div className="w-full border px-5">
      {(errorMessage || successMessage) && (
        <div
          className={`alert ${errorMessage ? "alert-danger" : "alert-success"}`}
          role="alert"
        >
          {errorMessage || successMessage}
        </div>
      )}
      <TransferRequestType
        requestType={requestType}
        setRequestType={setRequestType}
      />
      {requestType && (
        <RequestsCases
          aerialTransferType={aerialTransferType}
          setAerialTransferType={setAerialTransferType}
          bptTransferType={bptTransferType}
          setBptTransferType={setBptTransferType}
          requestType={requestType}
        />
      )}
      {requestType === REQUEST_TYPE_ENUM.CONSTRUCTION && bptTransferType ? (
        <div>
          <ConstructionInputs
            requestIds={requestIds}
            setRequestIds={setRequestIds}
            handleTransfer={handleTransfer}
            loading={loading}
            bptTransferType={bptTransferType}
            email={email}
            setEmail={setEmail}
            customerEmail={customerEmail}
            setCustomerEmail={setCustomerEmail}
          />
        </div>
      ) : (
        requestType === REQUEST_TYPE_ENUM.AERIAL &&
        aerialTransferType && (
          <div>
            {aerialTransferType ===
              AERIAL_TANSFER_TYPE_ENUM.NOT_COPY_AND_CHARGE && (
              <AerialInputTypes
                isFlockTransfer={isFlockTransfer}
                setIsFlockTransfer={setIsFlockTransfer}
                transferType={transferType}
                setTransferType={setTransferType}
                proceedBtn={proceedBtn}
                setProceedBtn={setProceedBtn}
              />
            )}
            {!proceedBtn && (
              <AerialInputs
                isBPT={isBPT}
                requestIds={requestIds}
                setRequestIds={setRequestIds}
                proceedBtn={proceedBtn}
                featureReportId={featureReportId}
                setFeatureReportId={setFeatureReportId}
                portfolioName={portfolioName}
                setPortfolioName={setPortfolioName}
                perAcreCost={perAcreCost}
                setPerAcreCost={setPerAcreCost}
                isFlockTransfer={isFlockTransfer}
                transferType={transferType}
                aerialTransferType={aerialTransferType}
                folderIds={folderIds}
                setFolderIds={setFolderIds}
                folderName={folderName}
                setFolderName={setFolderName}
                retry={retry}
                setRetry={setRetry}
                setRemeasurementRequest={setRemeasurementRequest}
                remeasurementRequest={remeasurementRequest}
                email={email}
                setEmail={setEmail}
                handleTransfer={handleTransfer}
                loading={loading}
                customerEmail={customerEmail}
                setCustomerEmail={setCustomerEmail}
              />
            )}
          </div>
        )
      )}
    </div>
  );
};

const RequestsCases = ({
  aerialTransferType,
  setAerialTransferType,
  bptTransferType,
  setBptTransferType,
  requestType,
}) => {
  const [isAccordianOpen, setIsAccordianOpen] = useState({
    NOT_COPY_AND_CHARGE: false,
    COPY_AND_NOT_CHARGE: false,
    NOT_COPY_AND_NOT_CHARGE: false,
  });

  const isBPT = requestType === REQUEST_TYPE_ENUM.CONSTRUCTION;
  const heading = `Select Transfer Type for ${
    isBPT ? "Construction" : "Aerial"
  } Requests: `;

  return (
    <div>
      <h1 className="text-center text-xl mt-4 font-medium">{heading}</h1>
      <div className="form-check form-check-inline w-full ">
        <div className="mt-4">
          <div
            className="flex pointer"
            onClick={() =>
              isBPT
                ? setBptTransferType(BPT_TANSFER_TYPE_ENUM.NOT_COPY_AND_CHARGE)
                : setAerialTransferType(
                    AERIAL_TANSFER_TYPE_ENUM.NOT_COPY_AND_CHARGE
                  )
            }
          >
            <input
              type="radio"
              checked={
                isBPT
                  ? bptTransferType ===
                    BPT_TANSFER_TYPE_ENUM.NOT_COPY_AND_CHARGE
                  : aerialTransferType ===
                    AERIAL_TANSFER_TYPE_ENUM.NOT_COPY_AND_CHARGE
              }
              className="mx-2"
            />
            <label>
              Move (Original transferred, charge applies to customer)
            </label>
            {!isBPT && (
              <div>
                <div
                  onClick={() =>
                    setIsAccordianOpen({
                      ...isAccordianOpen,
                      NOT_COPY_AND_CHARGE: !isAccordianOpen.NOT_COPY_AND_CHARGE,
                    })
                  }
                  className="ml-4"
                >
                  <Arrow />
                </div>
              </div>
            )}
          </div>
          {isAccordianOpen.NOT_COPY_AND_CHARGE && (
            <div className="ml-8 my-2 w-1/2 bg-light border rounded-md p-2">
              <p className="">
                This should be selected for portfolio transfers from flock or
                falcon.
              </p>
            </div>
          )}
        </div>
        <div className="mt-4">
          <div
            className="flex pointer"
            onClick={() =>
              isBPT
                ? setBptTransferType(BPT_TANSFER_TYPE_ENUM.COPY_AND_NOT_CHARGE)
                : setAerialTransferType(
                    AERIAL_TANSFER_TYPE_ENUM.COPY_AND_NOT_CHARGE
                  )
            }
          >
            <input
              type="radio"
              checked={
                isBPT
                  ? bptTransferType ===
                    BPT_TANSFER_TYPE_ENUM.COPY_AND_NOT_CHARGE
                  : aerialTransferType ===
                    AERIAL_TANSFER_TYPE_ENUM.COPY_AND_NOT_CHARGE
              }
              className="mx-2"
            />
            <label>
              Copy & Transfer (Duplicate created, no charge for customer)
            </label>
            {!isBPT && (
              <div>
                <div
                  onClick={() =>
                    setIsAccordianOpen({
                      ...isAccordianOpen,
                      COPY_AND_NOT_CHARGE: !isAccordianOpen.COPY_AND_NOT_CHARGE,
                    })
                  }
                  className="ml-4"
                >
                  <Arrow />
                </div>
              </div>
            )}
          </div>
          {isAccordianOpen.COPY_AND_NOT_CHARGE && (
            <div className="ml-8 my-2 w-1/2 bg-light border rounded-md p-2">
              <p className="">
                This should be chosen for the cases where requests are being
                transferred from internal accounts to the user's account .
              </p>
            </div>
          )}
        </div>
        <div className="mt-4">
          <div
            className="flex pointer"
            onClick={() =>
              isBPT
                ? setBptTransferType(
                    BPT_TANSFER_TYPE_ENUM.NOT_COPY_AND_NOT_CHARGE
                  )
                : setAerialTransferType(
                    AERIAL_TANSFER_TYPE_ENUM.NOT_COPY_AND_NOT_CHARGE
                  )
            }
          >
            <input
              type="radio"
              checked={
                isBPT
                  ? bptTransferType ===
                    BPT_TANSFER_TYPE_ENUM.NOT_COPY_AND_NOT_CHARGE
                  : aerialTransferType ===
                    AERIAL_TANSFER_TYPE_ENUM.NOT_COPY_AND_NOT_CHARGE
              }
              className="mx-2"
            />
            <label>Internal Transfer (Original transferred, no charge)</label>
            {!isBPT && (
              <div>
                <div
                  onClick={() =>
                    setIsAccordianOpen({
                      ...isAccordianOpen,
                      NOT_COPY_AND_NOT_CHARGE:
                        !isAccordianOpen.NOT_COPY_AND_NOT_CHARGE,
                    })
                  }
                  className="ml-4"
                >
                  <Arrow />
                </div>
              </div>
            )}
          </div>
          {isAccordianOpen.NOT_COPY_AND_NOT_CHARGE && (
            <div className="ml-8 my-2 w-1/2 bg-light border rounded-md p-2">
              <p className="">
                This option should only be chosen for internal transfers, if it
                is chosen there will be problems with billing.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const AerialInputs = ({
  requestIds,
  setRequestIds,
  featureReportId,
  setFeatureReportId,
  portfolioName,
  setPortfolioName,
  perAcreCost,
  setPerAcreCost,
  isFlockTransfer,
  transferType,
  aerialTransferType,
  setFolderIds,
  folderIds,
  folderName,
  setFolderName,
  retry,
  setRetry,
  remeasurementRequest,
  setRemeasurementRequest,
  email,
  setEmail,
  handleTransfer,
  loading,
  customerEmail,
  setCustomerEmail,
}) => {
  return (
    <div className="my-12">
      <IdInputs
        requestIds={requestIds}
        setRequestIds={setRequestIds}
        isFlockTransfer={isFlockTransfer}
        transferType={transferType}
      />
      {aerialTransferType !==
        AERIAL_TANSFER_TYPE_ENUM.NOT_COPY_AND_NOT_CHARGE &&
        aerialTransferType === AERIAL_TANSFER_TYPE_ENUM.COPY_AND_NOT_CHARGE && (
          <div className="input-group mb-3">
            <p className="input-group-text w-2/5">Customer Email: </p>
            <input
              type="Email"
              value={customerEmail}
              onChange={(e) => setCustomerEmail(e.target.value)}
              className="form-control"
              placeholder="Enter the customer user email id to whom you want to transfer the request"
              required
            />
          </div>
        )}
      {aerialTransferType !==
        AERIAL_TANSFER_TYPE_ENUM.NOT_COPY_AND_NOT_CHARGE &&
        aerialTransferType === AERIAL_TANSFER_TYPE_ENUM.NOT_COPY_AND_CHARGE && (
          <div className="input-group mb-3">
            <p className="input-group-text w-2/5">Customer Email: </p>
            <input
              type="text w-3/5"
              value={customerEmail}
              onChange={(e) => setCustomerEmail(e.target.value)}
              className="form-control"
              placeholder="Enter the customer email id to whom you want to transfer the request"
              required
            />
          </div>
        )}
      {aerialTransferType ===
        AERIAL_TANSFER_TYPE_ENUM.NOT_COPY_AND_NOT_CHARGE && (
        <div className="input-group mb-3">
          <p className="input-group-text w-2/5">Email Id: </p>
          <input
            type="email w-3/5"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
            placeholder="Enter the customer email id to whom you want to transfer the request"
            required
          />
        </div>
      )}
      {isFlockTransfer && (
        <div className="input-group mb-3">
          <p className="input-group-text w-2/5">Feature Report Id: </p>
          <input
            type="text w-3/5"
            value={featureReportId}
            onChange={(e) => setFeatureReportId(e.target.value)}
            className="form-control"
            placeholder="Enter feature report id"
            required
          />
        </div>
      )}
      {transferType === TRANSFER_TYPE_ENUM.PORTFOLIO_TRANSFER &&
        aerialTransferType === AERIAL_TANSFER_TYPE_ENUM.NOT_COPY_AND_CHARGE && (
          <>
            <div className="input-group mb-3">
              <p className="input-group-text w-2/5">Portfolio Name: </p>
              <input
                type="text w-3/5"
                value={portfolioName}
                onChange={(e) => setPortfolioName(e.target.value)}
                className="form-control"
                placeholder="Enter portfolio name"
                required
              />
            </div>

            <div className="input-group mb-3">
              <p className="input-group-text w-2/5">Folder Name: </p>
              <input
                type="text w-3/5"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                className="form-control"
                placeholder="Enter folder name"
                required
              />
            </div>

            <div className="input-group mb-3">
              <p className="input-group-text w-2/5">Per acre cost: </p>
              <input
                type="text w-3/5"
                value={perAcreCost}
                onChange={(e) => setPerAcreCost(e.target.value)}
                className="form-control"
                placeholder="Per acre cost in cents"
                required
              />
            </div>
          </>
        )}
      {aerialTransferType !== AERIAL_TANSFER_TYPE_ENUM.NOT_COPY_AND_CHARGE && (
        <IdInputs
          requestIds={folderIds}
          setRequestIds={setFolderIds}
          isFolder
        />
      )}
      {transferType === TRANSFER_TYPE_ENUM.PORTFOLIO_TRANSFER &&
        aerialTransferType === AERIAL_TANSFER_TYPE_ENUM.NOT_COPY_AND_CHARGE && (
          <div className="input-group mb-3">
            <p
              className="form-check-label mr-4 ml-2"
              htmlFor="flexCheckDefault"
            >
              Is Remeasurement Request:
            </p>
            <input
              className="form-check-input"
              type="checkbox"
              checked={remeasurementRequest}
              onChange={(e) => setRemeasurementRequest(e.target.checked)}
              id="blueprint"
            />
          </div>
        )}
      {isFlockTransfer && (
        <div className="input-group mb-3">
          <p className="form-check-label mr-4 ml-2" htmlFor="flexCheckDefault">
            Retry:
          </p>
          <input
            className="form-check-input"
            type="checkbox"
            checked={retry}
            onChange={(e) => setRetry(e.target.checked)}
            id="blueprint"
          />
        </div>
      )}
      <div className="text-center my-2">
        <button
          type="submit"
          onClick={handleTransfer}
          className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 mt-3 rounded"
        >
          Transfer Aerial Requests
          {loading && <LoaderCircle />}
        </button>
      </div>
    </div>
  );
};

const AerialInputTypes = ({
  isFlockTransfer,
  setIsFlockTransfer,
  transferType,
  setTransferType,
  proceedBtn,
  setProceedBtn,
}) => {
  return (
    <div className="w-2/3">
      <TypeOfTransfer
        transferType={transferType}
        setTransferType={setTransferType}
      />
      {transferType === TRANSFER_TYPE_ENUM.PORTFOLIO_TRANSFER && (
        <div className="input-group mb-3 ">
          <p className="input-group-text w-1/2">
            Is it a Flock to Falcon transfer?{" "}
          </p>
          <div className="dropdown w-1/2">
            <button
              className="btn border text-dark dropdown-toggle w-full"
              type="button"
              data-toggle="dropdown"
              data-bs-toggle="dropdown"
            >
              {isFlockTransfer ? "Yes" : "No"}
            </button>
            <div className="dropdown-menu w-full">
              <span
                className="dropdown-item pointer"
                onClick={(e) => setIsFlockTransfer(true)}
              >
                Yes
              </span>
              <span
                className="dropdown-item pointer"
                onClick={(e) => setIsFlockTransfer(false)}
              >
                No
              </span>
            </div>
          </div>
        </div>
      )}
      <div className="text-center mb-3">
        {proceedBtn && (
          <button
            className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 mt-3 rounded"
            onClick={() => setProceedBtn(false)}
          >
            Proceed
          </button>
        )}
      </div>
    </div>
  );
};

const TypeOfTransfer = ({ transferType, setTransferType }) => {
  return (
    <div className="mt-5">
      <div className="input-group mb-3 ">
        <p className="input-group-text w-1/2">Type of Transfer</p>
        <div className="dropdown w-1/2">
          <button
            className="btn border text-dark dropdown-toggle w-full"
            type="button"
            data-toggle="dropdown"
            data-bs-toggle="dropdown"
          >
            {TRANSFER_TYPE[transferType]}
          </button>
          <div className="dropdown-menu w-full">
            <span
              className="dropdown-item pointer"
              onClick={(e) =>
                setTransferType(TRANSFER_TYPE_ENUM.PORTFOLIO_TRANSFER)
              }
            >
              {TRANSFER_TYPE[TRANSFER_TYPE_ENUM.PORTFOLIO_TRANSFER]}
            </span>
            <span
              className="dropdown-item pointer"
              onClick={(e) => setTransferType(TRANSFER_TYPE_ENUM.NON_PORTFOLIO)}
            >
              {TRANSFER_TYPE[TRANSFER_TYPE_ENUM.NON_PORTFOLIO]}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const CSVUpload = ({
  setRequestIds,
  isBPT,
  isFolder,
  isFlockTransfer,
  transferType,
}) => {
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const str = reader?.result;
        const lines = str.split(/\r?\n/);

        // Extract the first value before the comma for each line
        const firstValues = lines.map((line) => {
          const values = line.split(",")[0];
          return values;
        });

        setRequestIds(firstValues);
      };
      reader.readAsText(file);
    }
  };

  const inputHeading =
    isBPT ||
    !isFlockTransfer ||
    transferType === TRANSFER_TYPE_ENUM.NON_PORTFOLIO
      ? "Upload Request Ids: "
      : isFolder
      ? "Upload Folder Ids: "
      : "Upload Orchestrator/Batch Ids: ";

  return (
    <div className="input-group mb-3">
      <p className="input-group-text w-2/5">{inputHeading}</p>
      <input
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={(e) => handleFileInputChange(e)}
        className="form-control w-3/5"
      />
    </div>
  );
};

const IdInputs = ({
  requestIds,
  setRequestIds,
  isBPT,
  isFolder,
  isFlockTransfer,
  transferType,
}) => {
  const inputHeading = isBPT
    ? "Construction Request Ids: "
    : isFolder
    ? "Folder Ids: "
    : !isFlockTransfer || transferType === TRANSFER_TYPE_ENUM.NON_PORTFOLIO
    ? "Request IDs: "
    : "Orchestrator IDs/ Batch IDs: ";
  const placeholder = `Enter comma separated automeasure ${
    isBPT
      ? "construction request"
      : isFolder
      ? "aerial folder"
      : `aerial${isFlockTransfer ? " orchestrator or" : ""} request`
  } ids`;
  return (
    <div className="">
      <div className="input-group mb-3">
        <p className="input-group-text w-2/5">{inputHeading}</p>
        <input
          type="text w-3/5"
          value={requestIds}
          onChange={(e) => setRequestIds(e.target.value)}
          className="form-control "
          placeholder={placeholder}
          required
        />
      </div>
      {!isFolder && (
        <>
          <p className="text-center text-xl font-medium my-4">OR</p>
          <CSVUpload
            setRequestIds={setRequestIds}
            isBPT={isBPT}
            isFolder={isFolder}
            isFlockTransfer={isFlockTransfer}
            transferType={transferType}
          />
        </>
      )}
    </div>
  );
};

const ConstructionInputs = ({
  requestIds,
  setRequestIds,
  handleTransfer,
  loading,
  bptTransferType,
  email,
  setEmail,
  setCustomerEmail,
  customerEmail,
}) => {
  return (
    <div className="mt-4 mx-2">
      {bptTransferType !== BPT_TANSFER_TYPE_ENUM.NOT_COPY_AND_NOT_CHARGE &&
        bptTransferType !== BPT_TANSFER_TYPE_ENUM.COPY_AND_NOT_CHARGE && (
          <div className="input-group mb-3">
            <p className="input-group-text w-2/5">Customer Email: </p>
            <input
              type="text w-3/5"
              value={customerEmail}
              onChange={(e) => setCustomerEmail(e.target.value)}
              className="form-control"
              placeholder="Enter the customer email id to whom you want to transfer the request"
              required
            />
          </div>
        )}

      {bptTransferType === BPT_TANSFER_TYPE_ENUM.COPY_AND_NOT_CHARGE && (
        <div className="input-group mb-3">
          <p className="input-group-text w-2/5">Customer Email: </p>
          <input
            type="Email"
            value={customerEmail}
            onChange={(e) => setCustomerEmail(e.target.value)}
            className="form-control"
            placeholder="Enter the customer user email id to whom you want to transfer the request"
            required
          />
        </div>
      )}

      <IdInputs requestIds={requestIds} setRequestIds={setRequestIds} isBPT />
      {bptTransferType === BPT_TANSFER_TYPE_ENUM.NOT_COPY_AND_NOT_CHARGE && (
        <div className="input-group mb-3">
          <p className="input-group-text w-2/5">Email Id: </p>
          <input
            type="email w-3/5"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
            placeholder="Enter the customer email id to whom you want to transfer the request"
            required
          />
        </div>
      )}
      <div className="text-center my-2">
        <button
          type="submit"
          onClick={handleTransfer}
          className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 mt-3 rounded"
        >
          Transfer Construction Requests
          {loading && <LoaderCircle />}
        </button>
      </div>
    </div>
  );
};

const TransferRequestType = ({ requestType, setRequestType }) => {
  return (
    <div className="">
      <p className="text-3xl font-semibold text-center my-5">
        Copy/Transfer Requests
      </p>
      <div className="border-b pb-4">
        <p className="text-xl font-medium text-center">Transfer type: </p>
        <div
          className={`form-check form-check-inline w-full ${
            requestType && "flex justify-around"
          }`}
        >
          <div
            onClick={() => setRequestType(REQUEST_TYPE_ENUM.AERIAL)}
            className="pointer mt-4 "
          >
            <input
              type="radio"
              checked={requestType === REQUEST_TYPE_ENUM.AERIAL}
              className="mx-2"
            />
            <label>You want to transfer Aerial requests</label>
          </div>
          <div
            onClick={() => {
              setRequestType(REQUEST_TYPE_ENUM.CONSTRUCTION);
            }}
            className="pointer mt-4"
          >
            <input
              type="radio"
              checked={requestType === REQUEST_TYPE_ENUM.CONSTRUCTION}
              className="mx-2"
            />
            <label>You want to transfer Construction requests</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyTransfer;
