import { Input } from "@attentive-platform/stem-ui";
import { isValidEmail } from "../Utils/HelperFunctions";

export const EmailInput = ({ value, onChange, name = "email" }) => {
  const _isValidEmail = value ? isValidEmail(value) : true;
  return (
    <Input
      name={name}
      color='secondary'
      size='medium'
      fullWidth
      placeholder='Email of user'
      value={value}
      onChange={(e) => {
        onChange("email", e.target.value);
      }}
      error={!_isValidEmail}
      helperText={!_isValidEmail && "Invalid email"}
    />
  );
};
