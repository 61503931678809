export const Loader = ({ sm = false }) => {
  const btnSize = sm ? "btn-sm" : "btn-lg";
  const loaderSize = sm ? "spinner-border-sm mt-2" : "spinner-border-lg";
  return (
    <div className="text-center">
      <button
        className={"btn btn-outline-secondary border-0 " + btnSize}
        type="button"
        disabled
      >
        <div className="d-flex">
          <span
            className={
              "spinner-border spinner-border-sm m-1 text-secondary " +
              loaderSize
            }
            aria-hidden="true"
          ></span>
          <p className="m-1 text-secondary" role="status">
            Loading...
          </p>
        </div>
      </button>
    </div>
  );
};
