import { BulkFileUpload } from "@attentive-platform/stem-ui";
import React, { useState } from "react";

function BulkFilesUpload({ acceptedExtensions, setFile }) {
  const [state, setState] = useState("pending");
  const [dynamicProgress, setDynamicProgress] = useState(20);

  const handleFileChange = (file) => {
    setFile(file);
    setState("pending");
    setTimeout(() => setState("uploading"), 1000);
    setTimeout(() => setState("complete"), 2000);
    setTimeout(() => setDynamicProgress((prev) => prev + 20), 1500);
  };

  return (
    <BulkFileUpload
      maxFileCount={1}
      color="primary"
      showUploadArea
      handleFileUpload={handleFileChange}
      state={state}
      progressValue={dynamicProgress}
      acceptedExtensions={acceptedExtensions}
    />
  );
}

export default BulkFilesUpload;
