import React from "react";
import { useNavigate } from "react-router-dom";

import { FORM_COMPONENTS, FORM_LABELS } from "../Constants/Constants";

const FormSelector = ({ onChange }) => {
  const navigate = useNavigate();

  return (
    <select
      className="form-select form-select-lg mb-3"
      aria-label="Default select example"
      onChange={(e) => {
        if (FORM_LABELS[e.target.value] === FORM_LABELS.COPY_TRANSFER) {
          navigate("/copy-transfer");
        } else onChange(e.target.value);
      }}
    >
      <option>Select Action</option>
      {Object.keys(FORM_COMPONENTS).map((key) => (
        <option key={key} value={key}>
          {FORM_LABELS[key]}
        </option>
      ))}
    </select>
  );
};

export default FormSelector;
