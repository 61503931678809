import { Button, Divider, Modal, showToast } from "@attentive-platform/stem-ui";
import InputField from "../InputField";
import { useState } from "react";
import { fetchApiData } from "../../Utils/ApiCalls";
import { FOLDER_EXPORT } from "../../urls";

const GeneratePortfolio = ({ show, setShow }) => {
  const [folderId, setFolderId] = useState("");

  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (key, value) => {
    setFolderId(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!folderId) {
      setError({
        folderId: "Folder ID is required",
      });
      return;
    }
    setLoading(true);
    const { error } = await fetchApiData({
      endpoint: FOLDER_EXPORT,
      apiRequest: "POST",
      data: {
        folder_id: folderId,
      },
    });
    if (error) {
      showToast({
        message: error,
        type: "error",
      });
      setLoading(false);
      setFolderId("");
      return;
    }
    showToast({
      message: "Portfolio generated successfully",
      type: "success",
    });
    setFolderId("");
    setLoading(false);
    setShow(false);
  };

  return (
    <Modal
      show={show}
      heading={<span className="pl-4">Generate Porfolio</span>}
      onClose={() => {
        setShow(false);
      }}
      content={
        <div className="flex flex-col gap-2 m-4  w-[500px]">
          <InputField
            label="Folder ID"
            keyName="folderId"
            type="text"
            placeholder="Enter folder ID"
            value={folderId}
            onChange={handleChange}
            error={error?.folderId}
            extraProps={{
              className: "w-full",
            }}
          />

          <Divider />
          <div className="flex justify-end gap-2">
            <Button
              size="medium"
              variant="outlined"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </Button>
            <Button loading={loading} size="medium" onClick={handleSubmit}>
              Generate
            </Button>
          </div>
        </div>
      }
    />
  );
};
export default GeneratePortfolio;
