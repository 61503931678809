import {
  BILLING_FREQUENCY_ENUM,
  COUNTRY_CODE,
  EMAIL_REGX,
  STATUS_ENUM,
} from "../Constants/Constants";

export const isValidPostalCode = (postalCode, countryCode) => {
  if (!postalCode || !countryCode) return false;
  let postalCodeRegex;
  switch (countryCode) {
    case "US":
      postalCodeRegex = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;
      break;
    case "CA":
      postalCodeRegex = /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/;
      break;
    default:
      postalCodeRegex = /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/;
  }
  return postalCodeRegex.test(postalCode);
};

export const isMobileDevice = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

// Update contract

// set enterprise attributes
export const setInitialFormData = ({
  email = "",
  enterpriseAttributes = {},
  platformAccess = {},
}) => {
  const {
    reports_given = [],
    ignore_marketing_mailer = true,
    ignore_draft_limit = true,
    ignore_size_limit = true,
    max_size_limit = 0,
    restrict_report_switching = true,
    restrict_custom_report_creation = true,
    ignore_concurrency_limit = false,
    show_optional_feature = false,
    concurrency_value = 2,
    min_measurement_size_unit = 0,
    apply_role_level_restriction = false,
    subdomain = "",
    callback_url = "",
    concurrency_value_construction = 2,
    is_pilot = false,
    default_report = {},
  } = enterpriseAttributes;

  const { end_date = "", start_date = "" } = platformAccess || {};

  return {
    email,
    start_date,
    end_date,
    reports_given,
    default_report: default_report.id,
    ignore_marketing_mailer,
    ignore_draft_limit,
    ignore_size_limit,
    max_size_limit,
    restrict_report_switching,
    restrict_custom_report_creation,
    ignore_concurrency_limit,
    show_optional_feature,
    concurrency_value,
    min_measurement_size_unit,
    apply_role_level_restriction,
    subdomain,
    callback_url,
    concurrency_value_construction,
    is_pilot,
  };
};

export const setContractInfoData = ({ enterpriseContract, billingDetails }) => {
  const {
    start_date = "",
    end_date = "",
    billing_frequency = BILLING_FREQUENCY_ENUM.QUATERLY,
    aerial_contract_amount = "",
    per_acre_cost = "",
    remeasurement_contract_amount = "",
    remeasurement_per_acre_cost = "",
    bpt_contract_amount = "",
    workable_sheet_cost = "",
    addendum_sheet_cost = "",
    manual_worksheet_cost = "",
    manual_per_acre_cost = "",
    manual_threshold_acres = "",
  } = enterpriseContract || {};

  const {
    email,
    name = "",
    address = "",
    city = "",
    zip = "",
    country = COUNTRY_CODE.USA,
  } = billingDetails || {};

  return {
    billing_email: email,
    company_name: name,
    address,
    city,
    zip,
    country,
    start_date,
    end_date,
    billing_frequency,
    aerial_contract_amount,
    per_acre_cost,
    remeasurement_contract_amount,
    remeasurement_per_acre_cost,
    bpt_contract_amount,
    workable_sheet_cost,
    addendum_sheet_cost,
    manual_worksheet_cost,
    manual_per_acre_cost,
    manual_threshold_acres,
  };
};

export const getStatus = (status) => {
  let [color, text, variant] = ["", "", ""];

  switch (status) {
    case STATUS_ENUM.CANCELLED_BY_CLIENT:
      color = "red";
      variant = "filled";
      text = "CANCELLED BY CLIENT";
      break;
    case STATUS_ENUM.WAITING_FOR_CLIENT:
      color = "orange";
      variant = "filled";
      text = "WAITING FOR CLIENT";
      break;
    case STATUS_ENUM.ESTIMATION:
      color = "darkBlue";
      variant = "filled";
      text = "ESTIMATION";
      break;
    case STATUS_ENUM.IN_PROGRESS:
      color = "gold";
      variant = "filled";
      text = "IN PROGRESS";
      break;
    case STATUS_ENUM.COMPLETED:
      color = "green";
      variant = "filled";
      text = "COMPLETED";
      break;
    case STATUS_ENUM.TO_DO:
      color = "neutral";
      variant = "filled";
      text = "TO DO";
      break;
    default:
      color = "orange";
      variant = "darkFilled";
      text = "EMPTY";
  }
  return [color, variant, text];
};

export const downloadFileFromUrl = (fileUrl) => {
  const aTag = document.createElement("a");
  aTag.href = fileUrl;
  aTag.download = `file-${new Date().getTime()}`;
  aTag.click();
  aTag.remove();
};

export const isValidEmail = (email) => (email ? EMAIL_REGX.test(email) : true);
