import { useCallback, useState } from "react";

const useFormStatus = () => {
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleApiResult = useCallback(({ data, error }) => {
    setTimeout(() => {
      setErrorMessage(null);
      setSuccessMessage(null);
    }, 5000);

    if (error) {
      setErrorMessage(error);
      setSuccessMessage(null);
    } else {
      setErrorMessage(null);
      typeof data === "string"
        ? setSuccessMessage(data)
        : setSuccessMessage("Success !");
    }
    return data;
  }, []);

  return {
    successMessage,
    errorMessage,
    setSuccessMessage,
    setErrorMessage,
    handleApiResult,
  };
};

export default useFormStatus;
