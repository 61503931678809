import { Input, showToast } from "@attentive-platform/stem-ui";
import React, { useState } from "react";
import { fetchApiData, interpolate } from "../../../Utils/ApiCalls";
import { PORTFOLIO_UPDATE } from "../../../urls";

const TakeOff = ({ row }) => {
  const [value, setValue] = useState(row.values.takeoff_type);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = async () => {
    const url = interpolate(PORTFOLIO_UPDATE, [row.original.id]);
    const { error } = await fetchApiData({
      endpoint: url,
      apiRequest: "PATCH",
      data: {
        takeoff_type: value,
      },
    });
    if (error) {
      showToast(error, "error");
      return;
    }
    showToast("TakeOff updated successfully", "success");
  };

  return (
    <div className="flex justify-center w-40">
      <Input
        size="small"
        className="w-44"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default TakeOff;
