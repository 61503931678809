import React, { useState } from "react";
import {
  USER_ROLE,
  USER_ROLE_ENUM,
  USER_TYPES,
  USER_TYPES_ENUM,
} from "../Constants/Constants";
import { INVITE_USER_ENDPOINT } from "../urls";
import { Alert } from "./Alert";
import useFormStatus from "../Hooks/useFormStatus";
import { fetchApiData } from "../Utils/ApiCalls";
import { Checkbox, Input, Select } from "./InputComponents";
import LoaderCircle from "./LoaderCircle";

export const InviteUser = () => {
  const [loading, setLoading] = useState(false);
  const { errorMessage, successMessage, handleApiResult } = useFormStatus();

  const initialFormData = {
    email: "",
    admin_email: "",
    is_lite_user: false,
    is_construction_signup: false,
    first_name: "",
    last_name: "",
    phone_number: "",
    role: USER_ROLE.TEAM_MEMBER,
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleDataChange = (val, field) => {
    setFormData({
      ...formData,
      [field]: val,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Make the POST request to the API with the form data
    setLoading(true);
    formData.email = formData.email.toLowerCase();
    formData.admin_email = formData.admin_email.toLowerCase();
    const response = await fetchApiData({
      endpoint: INVITE_USER_ENDPOINT,
      data: formData,
    });
    handleApiResult(response);
    setLoading(false);
    if (response.error === null) setFormData(initialFormData);
  };

  const inputFields = [
    {
      label: "First Name",
      type: "text",
      value: formData.first_name,
      onChange: (e) => handleDataChange(e.target.value, "first_name"),
      placeholder: "First Name",
    },
    {
      label: "Last Name",
      type: "text",
      value: formData.last_name,
      onChange: (e) => handleDataChange(e.target.value, "last_name"),
      placeholder: "Last Name",
    },
    {
      label: "User Email",
      type: "email",
      value: formData.email,
      onChange: (e) => handleDataChange(e.target.value, "email"),
      placeholder: "User Email",
    },
    {
      label: "Password",
      type: "text",
      value: formData.password,
      onChange: (e) => handleDataChange(e.target.value, "password"),
      placeholder: "Password",
    },
    {
      label: "Admin Email",
      type: "email",
      value: formData.admin_email,
      onChange: (e) => handleDataChange(e.target.value, "admin_email"),
      placeholder: "Associated Admin Email",
    },
    {
      label: "Phone number",
      type: "text",
      value: formData.phone_number,
      onChange: (e) => handleDataChange(e.target.value, "phone_number"),
      placeholder: "Phone number",
    },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <Alert errorMessage={errorMessage} successMessage={successMessage} />

      {inputFields.map((field, index) => (
        <Input key={index} {...field} />
      ))}

      <Select
        label="User type"
        value={formData.is_lite_user}
        onChange={(e) => {
          const val = e.target.value === "true";
          handleDataChange(val, "is_lite_user");
        }}
        options={USER_TYPES}
        optionType={USER_TYPES_ENUM}
      />

      <Select
        label="User Role"
        value={formData.role}
        onChange={(e) => handleDataChange(e.target.value, "role")}
        options={USER_ROLE}
        optionType={USER_ROLE_ENUM}
      />

      <Checkbox
        checked={formData.is_construction_signup}
        onChange={(e) =>
          handleDataChange(e.target.checked, "is_construction_signup")
        }
        type="checkbox"
        label="Construction Sign Up"
      />
      <div className="button">
        <button
          type="submit"
          className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Invite
          {loading && <LoaderCircle />}
        </button>
      </div>
    </form>
  );
};
