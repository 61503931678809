import { Input, showToast } from "@attentive-platform/stem-ui";
import React, { useState, useEffect } from "react";
import { fetchApiData, interpolate } from "../../../Utils/ApiCalls";
import { PORTFOLIO_UPDATE } from "../../../urls";

const PartialDelivery = ({ row }) => {
  const [value, setValue] = useState(row.values.partial_delivery);
  useEffect(() => {
    setValue(row.values.partial_delivery);
  }, [row.values.partial_delivery]);

  const handleBlur = async () => {
    if (value === row.values.partial_delivery) return;

    const url = interpolate(PORTFOLIO_UPDATE, [row.original.id]);
    const { error } = await fetchApiData({
      endpoint: url,
      apiRequest: "PATCH",
      data: { partial_delivery: value },
    });

    if (error) {
      showToast(error, "error");
      setValue("");
    } else {
      showToast("Partial Delivery updated successfully", "success");
    }
  };

  return (
    <Input
      size="small"
      className="w-44"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={handleBlur}
    />
  );
};

export default PartialDelivery;
