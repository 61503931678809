import { useState } from "react";
import { BLOCK_USER, CHANGE_PASSWORD } from "../urls";
import { postAPI } from "./ApiCalls";

export const changePassword = ({ data }) => postAPI(CHANGE_PASSWORD, data);
export const blockUser = ({ data }) => postAPI(BLOCK_USER, data);

export const useMutateService = (mutateFn) => {
  const [loading, setLoading] = useState(false);
  return {
    loading,
    mutateAsync: mutateFn,
    mutate: ({ data }, { onSuccess, onError }) => {
      setLoading(true);
      mutateFn({ data }, { onSuccess, onError })
        .then((response) => {
          if (!response.error) {
            if (onSuccess) onSuccess(response.data);
          } else {
            if (onError) onError(response.error);
          }
        })
        .finally(() => setLoading(false));
    },
  };
};

export const useChangePassword = () => {
  return useMutateService(changePassword);
};

export const useBlockUser = () => {
  return useMutateService(blockUser);
};
