import { useLocation, useNavigate } from "react-router-dom";
import { fetchApiData } from "../Utils/ApiCalls";
import { SIGNOUT_ENDPOINT } from "../urls";
import InitialIcon from "./InitialIcon";
import useFormStatus from "../Hooks/useFormStatus";
import { useEffect, useState } from "react";
import LoaderCircle from "./LoaderCircle";
import { ReactComponent as Logo } from "../Icons/attentive-logo.svg";
import { StyleProvider, Tab, Tabs } from "@attentive-platform/stem-ui";
import { Navigation } from "../Constants/Constants";
const Navbar = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleApiResult } = useFormStatus();
  const [loading, setLoading] = useState(false);

  const email = localStorage.getItem("userEmail");
  const initialLetter = email && email.charAt(0).toUpperCase();

  // Function to handle the logout action
  const handleSignout = async (event) => {
    event.preventDefault();
    setLoading(true);
    // Make the fetch request to the logout API
    const response = await fetchApiData({
      endpoint: SIGNOUT_ENDPOINT,
      apiRequest: "POST",
    });
    handleApiResult(response);
    // Perform any additional cleanup tasks, if necessary
    // For example, remove the token from local storage
    localStorage.clear();
    sessionStorage.clear();
    // Redirect the user to the login page or any other page
    // after successful logout
    setIsLoggedIn(false);
    setLoading(false);
    navigate("/login");
  };
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(Navigation[newValue]);
  };

  useEffect(() => {
    setValue(0);
    navigate(Navigation[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Object.entries(Navigation).forEach(([key, value]) => {
      if (location.pathname === value) {
        setValue(parseInt(key));
      }
    });
  }, [location.pathname]);

  return (
    <div>
      <div className="flex w-full h-16 justify-between items-center p-4">
        <a className="pb-4" href="/">
          <Logo />
        </a>
        {email && (
          <div className="flex items-center pr-2 gap-2">
            <a
              className=" cursor-pointer mb-0"
              data-toggle="tooltip"
              title={email}
              href="/"
            >
              <InitialIcon initials={initialLetter || ""} />
            </a>
            <div
              className="text-base font-medium text-[#F44A3E] cursor-pointer"
              onClick={handleSignout}
            >
              Log Out
              {loading && <LoaderCircle />}
            </div>
          </div>
        )}
      </div>
      {email && (
        <div className="flex bg-white justify-between shadow-md px-8 items-center">
          <div>
            <StyleProvider>
              {" "}
              <Tabs
                value={value}
                textColor="secondary"
                indicatorColor="secondary"
                onChange={handleChange}
              >
                <Tab label="CSM Dashboard" className="p-2" />
                <Tab label="Operations" className="p-2" />
                <Tab label="Companies" className="p-2" />
                <Tab label="Enterprise Contract Dashboard" className="p-2" />
                <Tab label="BPT" className="p-2" />
              </Tabs>
            </StyleProvider>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
