import {
  Button,
  Divider,
  Input,
  RadioGroup,
  StyleProvider,
  showToast,
} from "@attentive-platform/stem-ui";
import { ReactComponent as LeftCard } from "../Icons/left-card.svg";
import { useState } from "react";
import { FOLDER_EXPORT } from "../urls";
import { fetchApiData } from "../Utils/ApiCalls";
import { EXPORT_TYPE_ENUM } from "../Constants/Constants";

const GenerateDelivery = () => {
  const [selected, setSelected] = useState(EXPORT_TYPE_ENUM.FOLDER);
  const [folderId, setFolderId] = useState("");
  const [requestId, setRequestId] = useState("");
  const [orchestratorId, setOrchestratorId] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (event, newValue) => {
    resetFields();
    setSelected(+newValue);
  };

  const handleDataChange = (event) => {
    if (selected === EXPORT_TYPE_ENUM.FOLDER) {
      setFolderId(event.target.value);
    } else if (selected === EXPORT_TYPE_ENUM.REQUESTS) {
      setRequestId(event.target.value);
    } else {
      setOrchestratorId(event.target.value);
    }
  };
  const resetFields = () => {
    setFolderId("");
    setRequestId("");
    setOrchestratorId("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { error } = await fetchApiData({
      endpoint: FOLDER_EXPORT,
      apiRequest: "POST",
      data: {
        ...(selected === EXPORT_TYPE_ENUM.FOLDER
          ? { folder_id: folderId }
          : selected === EXPORT_TYPE_ENUM.REQUESTS
          ? { request_ids: requestId.split(",") }
          : { orchestrator_ids: orchestratorId.split(",") }),
      },
    });

    if (error) {
      showToast(error, "error");
      setLoading(false);
      return;
    }
    showToast("Exported  successfully", "success");
    resetFields();
    setLoading(false);
  };

  return (
    <StyleProvider theme="attentive-internal">
      <div className="flex justify-center">
        <div className="mr-20">
          <LeftCard />
        </div>
        <Divider className=" pl-6 pr-12" orientation="vertical" />
        <div className="flex flex-col pl-12 ">
          <div className="flex items-center ">
            <RadioGroup
              value={selected}
              size="small"
              radioGroup={[
                {
                  label: (
                    <div className="p-2">
                      Add Folder ID
                      <Input
                        onChange={handleDataChange}
                        disabled={
                          selected === EXPORT_TYPE_ENUM.FOLDER ? false : true
                        }
                        placeholder="Value"
                        size="small"
                        value={folderId}
                        variant="outlined"
                        className="w-[20rem] h-[12px]"
                      />
                    </div>
                  ),
                  value: EXPORT_TYPE_ENUM.FOLDER,
                },
                {
                  label: (
                    <div className="p-2">
                      Add request ID
                      <Input
                        onChange={handleDataChange}
                        disabled={
                          selected === EXPORT_TYPE_ENUM.REQUESTS ? false : true
                        }
                        placeholder="Value"
                        size="small"
                        value={requestId}
                        variant="outlined"
                        className="w-[20rem] h-[12px]"
                      />
                    </div>
                  ),
                  value: EXPORT_TYPE_ENUM.REQUESTS,
                },
                // {
                //   label: (
                //     <div className="p-2">
                //       Add Orchestrator ID
                //       <Input
                //         onChange={handleDataChange}
                //         disabled={
                //           selected === EXPORT_TYPE_ENUM.ORCHESTRATOR
                //             ? false
                //             : true
                //         }
                //         placeholder="Value"
                //         size="small"
                //         value={orchestratorId}
                //         variant="outlined"
                //         className="w-[20rem] h-[12px]"
                //       />
                //     </div>
                //   ),
                //   value: EXPORT_TYPE_ENUM.ORCHESTRATOR,
                // },
              ]}
              onChange={handleChange}
              variant="standard"
              color="primary"
              row
            />
          </div>
          <div className="flex pt-10 ">
            <Button
              size="medium"
              variant="contained"
              onClick={handleSubmit}
              loading={loading}
            >
              Generate Sheet
            </Button>
          </div>
        </div>
      </div>
    </StyleProvider>
  );
};
export default GenerateDelivery;
