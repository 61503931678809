import {
  Button,
  Divider,
  Modal,
  StyleProvider,
  showToast,
} from "@attentive-platform/stem-ui";
import InputField from "../InputField";
import { useState } from "react";
import BulkFilesUpload from "../BulkFileUpload";
import { fetchApiData } from "../../Utils/ApiCalls";
import { PORTFOLIO_ENDPOINT } from "../../urls";

const AddPortfolio = ({ show, setShow, getPortFolioData }) => {
  const [portfolio, setPortfolio] = useState({
    portfolio_name: "",
    takeoff_type: "",
    remark: "",
    file_url: "",
  });
  const requiredFields = [
    "portfolio_name",
    "takeoff_type",
    "file_url",
    "remark",
  ];
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (key, value) => {
    setPortfolio({
      ...portfolio,
      [key]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateFields(portfolio);
    setError(errors);
    let payload = new FormData();
    Object.keys(portfolio).forEach((key) => {
      payload.append(key, portfolio[key]);
    });
    setLoading(true);
    const { error } = await fetchApiData({
      endpoint: PORTFOLIO_ENDPOINT,
      apiRequest: "POST",
      data: payload,
    });
    setLoading(false);
    getPortFolioData();
    if (error) {
      showToast(error, "error");
      setPortfolio({
        portfolio_name: "",
        takeoff_type: "",
        remark: "",
        file_url: "",
      });
      return;
    }
    showToast("Portfolio added successfully", "success");
    setPortfolio({
      portfolio_name: "",
      takeoff_type: "",
      remark: "",
      file_url: "",
    });
    setShow(false);
  };
  const validateFields = (fields) => {
    const errors = {};
    requiredFields.forEach((field) => {
      if (!fields[field] || fields[field].length === 0) {
        errors[field] = `${field.replace(/_/g, " ")} is required`;
      }
    });
    return errors;
  };
  return (
    <Modal
      show={show}
      heading={<span className="pl-4">Add Portfolio</span>}
      onClose={() => {
        setShow(false);
      }}
      content={
        <StyleProvider theme="attentive-internal">
          <div className="flex flex-col gap-2 m-4  w-[500px]">
            <InputField
              label="Portfolio Name"
              keyName="portfolio_name"
              type="text"
              placeholder="Enter name"
              value={portfolio.portfolio_name}
              onChange={handleChange}
              error={error?.portfolio_name}
              extraProps={{
                className: "w-full",
              }}
            />
            <InputField
              label="Takeoff Type"
              keyName="takeoff_type"
              type="text"
              placeholder="Enter takeoff type"
              value={portfolio.takeoff_type}
              onChange={handleChange}
              error={error?.takeoff_type}
              extraProps={{
                className: "w-full",
              }}
            />

            <InputField
              label="Remark"
              keyName="remark"
              type="text"
              placeholder="Enter remark"
              value={portfolio.remark}
              onChange={handleChange}
              error={error?.remark}
              extraProps={{
                minRows: 3,
                multiline: true,
                className: "w-full",
              }}
            />
            <div>
              <span className="text-sm font-medium">Upload Portfolio:</span>
              <BulkFilesUpload
                acceptedExtensions={{
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    [".xlsx"],
                }}
                setFile={(file) => {
                  setPortfolio({
                    ...portfolio,
                    file_url: file[0],
                  });
                }}
              />
              {error?.file_url && (
                <span className="text-red-500 text-xs ">{error.file_url}</span>
              )}
            </div>
            <Divider />
            <div className="flex justify-end gap-2">
              <Button
                size="medium"
                variant="outlined"
                onClick={() => {
                  setShow(false);
                }}
              >
                Cancel
              </Button>
              <Button
                loading={loading}
                size="medium"
                color="primary"
                onClick={handleSubmit}
              >
                Add
              </Button>
            </div>
          </div>
        </StyleProvider>
      }
    />
  );
};
export default AddPortfolio;
