import { Input, showToast } from "@attentive-platform/stem-ui";
import React, { useEffect, useState } from "react";
import { fetchApiData, interpolate } from "../../../Utils/ApiCalls";
import { PORTFOLIO_UPDATE } from "../../../urls";

const Remark = ({ row }) => {
  const [value, setValue] = useState(row.values.remark);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  useEffect(() => {
    setValue(row.values.remark);
  }, [row.values.remark]);

  const handleBlur = async () => {
    if (value === row.values.remark) return;

    const url = interpolate(PORTFOLIO_UPDATE, [row.original.id]);
    const { error } = await fetchApiData({
      endpoint: url,
      apiRequest: "PATCH",
      data: { remark: value },
    });

    if (error) {
      showToast(error, "error");
      setValue(row.values.reamrk);
    } else {
      showToast("Remark updated successfully", "success");
    }
  };

  return (
    <div className="flex justify-center w-36">
      <Input
        size="small"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        minRows={3}
        multiline={true}
      />
    </div>
  );
};

export default Remark;
