import { useState } from "react";
import { fetchApiData, interpolate } from "../Utils/ApiCalls";
import {
  DOWNLOAD_USAGE_SUMMARY_GG_ENDPOINT,
  DOWNLOAD_USAGE_SUMMARY_USL,
  USAGE_SUMMARY_ENDPOINT,
} from "../urls";
import useFormStatus from "../Hooks/useFormStatus";
import { Alert } from "./Alert";
import LoaderCircle from "./LoaderCircle";
import { COMPANY_ENUM } from "../Constants/Constants";

const DownloadUsageSummary = ({ company }) => {
  const initDates = {
    startDate: "",
    endDate: "",
    minEndDate: "",
    maxEndDate: "",
  };

  const [userEmail, setUserEmail] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState(initDates);

  const { errorMessage, successMessage, handleApiResult } = useFormStatus();

  const isUsl = company === COMPANY_ENUM.USL;
  const isRexel = company === COMPANY_ENUM.REXEL;
  const generalUsage = !company;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      start_date: dates.startDate,
      end_date: dates.endDate,
    };
    if (generalUsage || isRexel) data.email = userEmail.toLowerCase();

    setLoading(true);
    const url =
      generalUsage || isRexel
        ? USAGE_SUMMARY_ENDPOINT
        : isUsl
        ? interpolate(DOWNLOAD_USAGE_SUMMARY_USL, [companyId])
        : DOWNLOAD_USAGE_SUMMARY_GG_ENDPOINT;
    const apiData = {
      endpoint: url,
    };
    if (isUsl) {
      apiData.params = data;
      apiData.apiRequest = "GET";
    } else {
      if (isRexel) apiData.params = { origin: 2 };
      apiData.data = data;
      apiData.apiRequest = "POST";
    }
    const res = await fetchApiData(apiData);
    handleApiResult(res);
    setLoading(false);
    if (res?.data) {
      isUsl && setCompanyId("");
      setDates(initDates);
      let resData;
      if (!isUsl || generalUsage) {
        resData = res?.data?.url;
      } else {
        resData = res?.data?.download;
      }
      window.open(resData, "_blank");
    }
  };

  const handleDateChange = (e) => {
    const date = e.target.value;
    // Min End Date should be equal or more than start date
    setDates({
      ...dates,
      startDate: date,
      minEndDate: date,
    });
  };

  return (
    <form onSubmit={handleSubmit} className="w-10/12">
      <Alert errorMessage={errorMessage} successMessage={successMessage} />
      {(generalUsage || isRexel) && (
        <div className="input-group mb-3 input-group-lg">
          <span className="input-group-text"> User Email</span>
          <input
            className="form-control"
            type="email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            placeholder="User Email"
            required
          />
        </div>
      )}
      {isUsl && (
        <div className="input-group mb-3 input-group-lg">
          <span className="input-group-text"> Company ID(uuid)</span>
          <input
            className="form-control"
            type="text"
            value={companyId}
            onChange={(e) => setCompanyId(e.target.value)}
            placeholder="uuid"
            required
          />
        </div>
      )}
      <div className="input-group input-group-lg">
        <span className="m-2">Completed at:</span>
      </div>
      <div className="input-group input-group-lg">
        <span className="input-group-text w-1/4">Start Date</span>
        <input
          className="form-control"
          type="date"
          value={dates.startDate}
          onChange={(e) => handleDateChange(e)}
          min="2023-01-01T00:00"
          max="2040-12-31T23:59"
        />
      </div>
      <div className="input-group mt-3 input-group-lg">
        <span className="input-group-text w-1/4">End Date</span>
        <input
          className="form-control"
          type="date"
          value={dates.endDate}
          onChange={(e) => setDates({ ...dates, endDate: e.target.value })}
          min={dates.minEndDate}
          max="2040-12-31T23:59"
          disabled={dates.startDate === ""}
        />
      </div>
      <div className="button">
        <button
          type="submit"
          className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Download
          {loading && <LoaderCircle />}
        </button>
      </div>
    </form>
  );
};

export default DownloadUsageSummary;
