import {
  COMPANY_STATUS_NEW,
  COMPANY_TYPE,
  SERVICE_TYPE,
} from "../../Constants/Constants";

export const getCompanyUsageColumns = () => {
  const columns = [
    {
      Header: "Company Name",
      accessor: "name",
      align: "center",
      isSticky: true,

      Cell: ({ row }) => {
        return (
          <div className="flex w-40 justify-center">
            {row?.values?.name ? row.values.name : "-"}
          </div>
        );
      },
    },
    {
      Header: "Company ID",
      accessor: "id",
      Cell: ({ row }) => {
        return row?.values?.id ? (
          <div className="flex justify-center w-40">{row.values.id}</div>
        ) : (
          "-"
        );
      },
      align: "center",
      allowColumnConfig: true,
    },

    {
      Header: "Admin Email",
      accessor: "admin_email",
      Cell: ({ row }) => {
        return (
          <div className="flex justify-center w-40">
            {row?.values?.admin_email ? row.values.admin_email : "-"}
          </div>
        );
      },
    },
    {
      Header: "Service Type",
      accessor: "service_type",
      Cell: ({ row }) => {
        return (
          <div className="flex justify-center w-40">
            {SERVICE_TYPE[row.values.service_type]}
          </div>
        );
      },
    },
    {
      Header: "Aerial Request Price",
      accessor: "aerial_request_price",
      Cell: ({ row }) => {
        return (
          <div className="flex justify-center w-40">
            {row?.values?.aerial_request_price != null
              ? row?.values?.aerial_request_price.toFixed(2)
              : "-"}
          </div>
        );
      },
    },
    {
      Header: "BluePrint Request Price",
      accessor: "blueprint_request_price",
      Cell: ({ row }) => {
        return (
          <div className="flex justify-center w-40">
            {row?.values?.blueprint_request_price != null
              ? row?.values?.blueprint_request_price.toFixed(2)
              : "-"}
          </div>
        );
      },
    },
    {
      Header: "Acres Consumed",
      accessor: "acre_consumed",
      Cell: ({ row }) => {
        return (
          <div className="flex justify-center w-40">
            {row?.values?.acre_consumed
              ? row?.values?.acre_consumed.toFixed(2)
              : "-"}
          </div>
        );
      },
      align: "center",
    },
    {
      Header: "Workable Sheet",
      accessor: "workable_sheet",
      Cell: ({ row }) => {
        return (
          <div className="flex justify-center w-40">
            {row.values.workable_sheet}
          </div>
        );
      },
      align: "center",
      allowColumnConfig: true,
    },

    {
      Header: "Addendum Sheet",
      accessor: "addendum_sheet",
      align: "center",
      allowColumnConfig: true,
    },
    {
      Header: "Company Type",
      accessor: "type",
      Cell: ({ row }) => {
        return (
          <div className="flex justify-center w-40">
            {row.values.type ? COMPANY_TYPE[row.values.type] : "-"}
          </div>
        );
      },
      align: "center",
    },
    {
      Header: "Company Status",
      accessor: "company_status",
      Cell: ({ row }) => {
        return (
          <div className="flex justify-center w-40">
            {row.values.company_status
              ? COMPANY_STATUS_NEW[row.values.company_status]
              : "-"}
          </div>
        );
      },
      align: "center",
    },
  ];
  return columns;
};
