import React, { useState } from "react";
import useFormStatus from "../Hooks/useFormStatus";
import { FREE_PROPERTY_DETAILS_ENDPOINT } from "../urls";
import { fetchApiData } from "../Utils/ApiCalls";
import { inputGroups } from "../Constants/Constants";
import { Alert } from "./Alert";
import LoaderCircle from "./LoaderCircle";

export const FreePropertyUpdateForm = () => {
  const initialData = {
    id: "",
    name: "",
    free_properties: "",
    free_properties_manual: "",
    free_properties_blueprint: "",
    free_properties_blueprint_manual: "",
  };

  const [freePropertyFetchedData, setFreePropertyFetchedData] =
    useState(initialData);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [formData, setFormData] = useState(initialData);
  const [email, setEmail] = useState("");
  const { successMessage, errorMessage, handleApiResult } = useFormStatus();

  // Rest of your component logic here

  const handleFormDataChange = (e, field) => {
    setFormData({
      ...formData,
      [field]: !isNaN(Number(e.target.value)) ? Number(e.target.value) : "", // If the input value is not a number, display an empty string ("") instead of "NaN" for better user experience.
    });
  };

  const handleSubmitRequest = async (e) => {
    e.preventDefault();
    setUpdating(true);
    formData.company = formData.id;
    const response = await fetchApiData({
      endpoint: FREE_PROPERTY_DETAILS_ENDPOINT,
      data: formData,
    });
    handleApiResult(response);

    if (response.error === null) {
      setFormData(response.data);
      setFreePropertyFetchedData(response.data);
    }
    setUpdating(false);
  };

  const handleSearchInputChange = (e) => [setEmail(e.target.value)];

  const handleSearchClick = async (e) => {
    setLoading(true);

    e.preventDefault();

    const response = await fetchApiData({
      endpoint: `${FREE_PROPERTY_DETAILS_ENDPOINT}?email=${encodeURIComponent(
        email.toLowerCase()
      )}`,
      data: null,
      apiRequest: "GET",
    });
    handleApiResult(response);
    setLoading(false);

    if (response.data) {
      setFormData(response.data);
      setFreePropertyFetchedData(response.data);
    }
  };

  return (
    <div className="mb-3">
      <Alert errorMessage={errorMessage} successMessage={successMessage} />
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Company Admin's Email"
          value={email}
          onChange={handleSearchInputChange}
          aria-label="Admin Email"
          aria-describedby="button-addon2"
        />
        <button
          className="btn btn-outline-secondary"
          type="button"
          id="button-addon2"
          onClick={handleSearchClick}
        >
          Search
          {loading && <LoaderCircle />}
        </button>
      </div>

      <div>
        {inputGroups.map((group, index) => (
          <div className="input-group mb-3" key={index}>
            <span className="input-group-text w-50" id={`basic-addon${index}`}>
              {group.label}
            </span>

            <input
              type="text"
              className={`form-control me-5 ms-5 ${
                group.extraClass || ""
              } input-text-align`}
              id="basic-url"
              aria-describedby={`basic-addon${index}`}
              disabled
              readOnly={group.readOnly}
              placeholder={
                group.field ? freePropertyFetchedData[group.field] : "FP Left"
              }
            />

            {
              <input
                type="text"
                className="form-control input-text-align"
                id="basic-url"
                disabled={!group.field}
                onChange={(e) => handleFormDataChange(e, group.field)}
                value={group.field ? formData[group.field] ?? "" : "Update"}
                aria-describedby={`basic-addon${index}`}
              />
            }
          </div>
        ))}
      </div>

      <div className="button">
        <button
          type="submit"
          onClick={handleSubmitRequest}
          className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Update
          {updating && <LoaderCircle />}
        </button>
      </div>
    </div>
  );
};
