import React from "react";

const Pagination = ({
  totalPages,
  setPage,
  page,
  pageNumbers,
  setRecordsPerPage,
  recordsPerPage,
}) => {
  return (
    <div className="flex justify-center">
      <nav>
        <ul className="pagination justify-center">
          <li className={`page-item ${page === 1 && "disabled"}`}>
            <div
              className="page-link pointer"
              onClick={() => setPage((page) => page - 1)}
            >
              Previous
            </div>
          </li>
          {pageNumbers.map((pageCount) => {
            const className = `page-link pointer ${
              page === pageCount && "active"
            }`;
            return (
              <li className="page-item" key={pageCount}>
                <div className={className} onClick={() => setPage(pageCount)}>
                  {pageCount}
                </div>
              </li>
            );
          })}
          <li className={`page-item ${page === totalPages && "disabled"}`}>
            <div
              className="page-link pointer"
              onClick={() => setPage((page) => page + 1)}
            >
              Next
            </div>
          </li>
        </ul>
      </nav>
      <div className="dropdown">
        <button
          className="btn border text-dark dropdown-toggle w-full"
          type="button"
          data-toggle="dropdown"
          data-bs-toggle="dropdown"
        >
          {recordsPerPage} / page
        </button>
        <div className="dropdown-menu pointer">
          <span
            className="dropdown-item  pointer"
            onClick={() => setRecordsPerPage(50)}
          >
            50 /page
          </span>
          <span
            className="dropdown-item  pointer"
            onClick={() => setRecordsPerPage(75)}
          >
            75 / page
          </span>
          <span
            className="dropdown-item pointer"
            onClick={() => setRecordsPerPage(100)}
          >
            100 / page
          </span>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
