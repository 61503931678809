import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { FEATURE_REPORT_ENDPOINT } from "../urls";
import { fetchApiData } from "../Utils/ApiCalls";
import useFormStatus from "../Hooks/useFormStatus";

const SearchComponent = ({
  setInputValue,
  setDefaultReport,
  selectedOptions,
  setSelectedOptions,
  defaultReport,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const [loading, setLoading] = useState(false);
  const [listOptions, setListOptions] = useState([]);

  const { handleApiResult } = useFormStatus();

  useEffect(() => {
    // Create an AbortController for the current request
    const controller = new AbortController();
    const { signal } = controller;

    if (searchTerm === "") {
      setListOptions([]);
      setLoading(false);
      return () => {
        // Abort the current request when the effect is cleaned up
        controller.abort();
      };
    }

    // Debouncing with setTimeout
    const debounceSearch = setTimeout(() => {
      const fetchData = async () => {
        setLoading(true);

        // Fetch data using the provided signal
        const response = await fetchApiData({
          endpoint: `${FEATURE_REPORT_ENDPOINT}?q=${searchTerm}`,
          apiRequest: "GET",
          signal,
        });

        handleApiResult(response);

        if (!signal.aborted && response.data) {
          setListOptions(response.data);
        }
        setLoading(false);
      };
      fetchData();
    }, 300);

    return () => {
      // Clear the timeout and abort the current request when the effect is cleaned up
      clearTimeout(debounceSearch);
      controller.abort();
    };
  }, [searchTerm, handleApiResult]);

  const customValueRenderer = (selected, _options) => {
    return selected.length
      ? `${selected.length} Reports Selected`
      : "No Reports Selected";
  };

  // as we have used the multiselect component so it takes in the options in {label:'',value:''} form
  // so converting it with th help to thid converter
  const convertedOptions = listOptions?.map((option) => ({
    label: option?.name + " - " + (option?.user_email || "None"),
    value: option.id.toString(), // You might need to convert it to a string if your value needs to be a string
  }));

  const handleDefaultReportChange = (event) => {
    setDefaultReport(event.target.value);
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  function handleChange(event) {
    setSelectedOptions(event);

    const selected = [];
    for (let i = 0; i < event.length; i++) {
      selected.push(event[i].value);
    }
    setInputValue(selected);
  }

  return (
    <>
      <div className="input-group mb-3">
        <input
          className="form-control"
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search Required Reports..."
        ></input>
        {loading && (
          <button className="btn btn-outline-secondary" type="button" disabled>
            <span
              className="spinner-border spinner-border-sm"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden" role="status">
              Loading...
            </span>
          </button>
        )}
      </div>

      {listOptions?.length > 0 && (
        <MultiSelect
          className="multiselect "
          valueRenderer={customValueRenderer}
          options={convertedOptions}
          isOpen
          value={selectedOptions}
          disableSearch
          hasSelectAll={false}
          onChange={handleChange}
          labelledBy="Select"
          required
        />
      )}
      {selectedOptions.length > 0 && (
        <>
          <span className="mt-3">Select Default Report</span>
          <select
            className="form-select  mb-3 "
            size="10"
            aria-label="Size 3 Multiple select example"
            onChange={handleDefaultReportChange}
            required
          >
            {selectedOptions.map((itemStr) => {
              const { label, value } = itemStr;
              const isSelected = value === defaultReport;
              return (
                <option key={value} value={value} selected={isSelected}>
                  {label}
                </option>
              );
            })}
          </select>
        </>
      )}
    </>
  );
};

export default SearchComponent;
