import { useState } from "react";
import { fetchApiData } from "../Utils/ApiCalls";
import useFormStatus from "../Hooks/useFormStatus";
import { INCREASE_FREE_TRIAL_ENDPOINT } from "../urls";
import IncreaseFreeTrial from "./IncreaseFreeTrial";

export const IncreaseFreeTrialForm = () => {
  const { successMessage, errorMessage, handleApiResult } = useFormStatus();

  const [email, setEmail] = useState("");
  const [numberOfDays, setNumberOfDays] = useState("1");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Make the API request to get the token from the backend

    const result = await fetchApiData({
      endpoint: INCREASE_FREE_TRIAL_ENDPOINT,
      data: {
        email: email.toLowerCase(),
        free_trial_days: numberOfDays.replace(/^0+/, ""),
      },
    });

    handleApiResult(result);
    setEmail("");
    setNumberOfDays(0);
  };
  return (
    <form onSubmit={handleSubmit} className="w-100">
      {(errorMessage || successMessage) && (
        <div
          className={`alert ${errorMessage ? "alert-danger" : "alert-success"}`}
          role="alert"
        >
          {errorMessage || successMessage}
        </div>
      )}
      <div className="input-group mb-3 w-100">
        <div className="w-100 ">
          <input
            className="form-control "
            aria-label="Text input with dropdown button"
            placeholder="Admin Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />
        </div>
      </div>
      <IncreaseFreeTrial
        numberOfDays={numberOfDays}
        setNumberOfDays={setNumberOfDays}
      />
      <div className="button">
        <button
          type="submit"
          className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Submit
        </button>
      </div>
    </form>
  );
};
