import { Input, showToast } from "@attentive-platform/stem-ui";
import React, { useState, useEffect } from "react";
import { PORTFOLIO_UPDATE } from "../../../urls";
import { fetchApiData, interpolate } from "../../../Utils/ApiCalls";

const ExecutedOn = ({ row }) => {
  const [value, setValue] = useState(row.values.executed_on);

  useEffect(() => {
    setValue(row.values.executed_on);
  }, [row.values.executed_on]);

  const handleBlur = async () => {
    if (value === row.values.executed_on) return;

    const url = interpolate(PORTFOLIO_UPDATE, [row.original.id]);
    const { error } = await fetchApiData({
      endpoint: url,
      apiRequest: "PATCH",
      data: { executed_on: value },
    });

    if (error) {
      showToast(error, "error");
      setValue(row.values.executed_on);
    } else {
      showToast("Executed on updated successfully", "success");
    }
  };

  return (
    <Input
      size="small"
      className="w-44"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={handleBlur}
    />
  );
};

export default ExecutedOn;
