import React from "react";
import { getStatus } from "../../../Utils/HelperFunctions";
import { Lozenge } from "@attentive-platform/stem-ui";

const Status = ({ status }) => {
  const [color, variant, text] = getStatus(status ?? 0);
  return <Lozenge color={color} label={text} variant={variant} size="small" />;
};

export default Status;
