import React from "react";

export const Input = ({ label, type, value, onChange, placeholder }) => {
  return (
    <div className="input-group mb-3 input-group-lg">
      <label className="input-group-text" htmlFor="inputGroupSelect01">
        {label}
      </label>
      <input
        type={type}
        value={value}
        onChange={onChange}
        required
        className="form-control"
        placeholder={placeholder}
      />
    </div>
  );
};

export const Select = ({ label, value, onChange, options, optionType }) => {
  return (
    <div className="input-group mb-3 input-group-lg">
      <label className="input-group-text">{label}</label>
      <select
        className="form-select"
        value={value}
        onChange={onChange}
        required
      >
        {Object.entries(options).map(([key, value]) => {
          const label = optionType[value];
          return (
            <option key={value} value={value}>
              {label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export const Checkbox = ({ checked, onChange, type, label }) => {
  return (
    <div className="form-check form-check-inline">
      <input
        className="form-check-input"
        type={type}
        checked={checked}
        onChange={onChange}
      />
      <label className="form-check-label" htmlFor="flexCheckDefault">
        {label}
      </label>
    </div>
  );
};
