import { Button, showToast } from "@attentive-platform/stem-ui";
import { useState } from "react";
import { FormContainer } from "../../styled";
import { EmailInput } from "../../uicomponents";
import { isValidEmail } from "../../Utils/HelperFunctions";
import { useBlockUser } from "../../Utils/services";

export const BlockUser = () => {
  const [email, setEmail] = useState("");
  const { mutate, loading } = useBlockUser();

  const onSubmitHandler = () => {
    const data = { email };
    mutate(
      { data },
      {
        onSuccess: () => {
          showToast("User blocked successfully", "success");
          setEmail("");
        },
        onError: (error) => {
          showToast(error, "error");
        },
      }
    );
  };

  return (
    <FormContainer>
      <div>
        <EmailInput
          name='bu-page-email'
          value={email}
          onChange={(_, value) => {
            setEmail(value);
          }}
        />
      </div>
      <div>
        <Button
          disabled={!email || !isValidEmail(email)}
          color='error'
          onClick={onSubmitHandler}
          loading={loading}
        >
          Block user
        </Button>
      </div>
    </FormContainer>
  );
};
