import React, { useState } from "react";
import { fetchApiData, interpolate } from "../../Utils/ApiCalls";

import {
  DropdownMenu,
  Modal,
  Button,
  Divider,
  showToast,
  StyleProvider,
} from "@attentive-platform/stem-ui";
import InputField from "../InputField";

import { PORTFOLIO_REVIEW_ENUM } from "../../Constants/Constants";
import { PORTFOLIO_EXECUTE } from "../../urls";

function PortfolioReviewAction({ row, refetchData }) {
  const menuItems = [
    { value: PORTFOLIO_REVIEW_ENUM.FALCON, label: "Run On Falcon" },
    { value: PORTFOLIO_REVIEW_ENUM.FLOCK, label: "Run On Flock" },
  ];
  const portfolioId = row?.original.id;
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const handleEmailChange = (key, value) => {
    setEmailId(value);
  };

  const handleMenuClick = async (e, item) => {
    if (item.value === PORTFOLIO_REVIEW_ENUM.FALCON) {
      setShow(true);
    } else if (item.value === PORTFOLIO_REVIEW_ENUM.FLOCK) {
      try {
        const res = await fetchApiData({
          endpoint: interpolate(PORTFOLIO_EXECUTE, [portfolioId]),
          apiRequest: "POST",
          data: {
            execute_on: PORTFOLIO_REVIEW_ENUM.FLOCK,
          },
        });
        if (res.error) {
          showToast(res.error, "error");
          return;
        }

        showToast("Portfolio review for Flock started successfully", "success");

        await refetchData();
      } catch (error) {
        showToast(error, "error");
      }
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleFalconSubmit = async () => {
    if (!validateEmail(emailId)) {
      setError("Invalid email address");
      return;
    }
    setError("");
    setLoading(true);

    try {
      const res = await fetchApiData({
        endpoint: interpolate(PORTFOLIO_EXECUTE, [portfolioId]),
        apiRequest: "POST",
        data: {
          execute_on: PORTFOLIO_REVIEW_ENUM.FALCON,
          user_email: emailId.toLowerCase(),
        },
      });
      if (res.error) {
        showToast(res.error, "error");
        setLoading(false);
        setEmailId("");
        return;
      }
      setShow(false);
      setLoading(false);
      setEmailId("");
      showToast("Portfolio review for Falcon started successfully", "success");
      await refetchData();
    } catch (error) {
      showToast(error, "error");
      return;
    }

    setShow(false);
  };

  return (
    <>
      <DropdownMenu
        color="secondary"
        open={open}
        menuItems={menuItems}
        onButtonClick={() => setOpen((prevState) => !prevState)}
        handleClose={() => setOpen(false)}
        onMenuItemClick={handleMenuClick}
        variant="outlined"
        IconDropdown
      />
      <Modal
        show={show}
        heading={<span className="pl-4">Enter email of user</span>}
        onClose={() => setShow(false)}
        content={
          <div className="flex flex-col gap-2 m-4 w-[500px]">
            <StyleProvider theme="attentive-internal">
              <InputField
                color="secondary"
                label="Email Id"
                type="text"
                placeholder="Enter Email Id"
                value={emailId}
                onChange={handleEmailChange}
                error={error}
                extraProps={{
                  className: "w-full",
                }}
              />
            </StyleProvider>
            <Divider />
            <div className="flex justify-end gap-2">
              <Button
                size="medium"
                variant="outlined"
                color="secondary"
                onClick={() => setShow(false)}
              >
                Cancel
              </Button>
              <Button
                loading={loading}
                size="medium"
                color="secondary"
                onClick={handleFalconSubmit}
              >
                Execute
              </Button>
            </div>
          </div>
        }
      />
    </>
  );
}

export default PortfolioReviewAction;
