import React, { useState, useEffect } from "react";

import Login from "./Components/Login";
import Navbar from "./Components/Navbar";
import MainPage from "./Components/MainPage";
import { StyleProvider, Toast } from "@attentive-platform/stem-ui";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");
    if (storedToken) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <div>
      <StyleProvider>
        <Toast
          newestOnTop={false}
          limit={1}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Navbar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />

        {!isLoggedIn ? <Login setIsLoggedIn={setIsLoggedIn} /> : <MainPage />}
      </StyleProvider>
    </div>
  );
}

export default App;
