import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchApiData } from "../Utils/ApiCalls";
import { SIGNIN_ENDPOINT } from "../urls";
import useFormStatus from "../Hooks/useFormStatus";
import { Alert } from "./Alert";
import LoaderCircle from "./LoaderCircle";

const Login = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { errorMessage, successMessage, handleApiResult } = useFormStatus();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const loginData = {
      email: email,
      password: password,
    };

    // Make the API request to get the token from the backend
    setLoading(true);
    const result = await fetchApiData({
      endpoint: SIGNIN_ENDPOINT,
      data: loginData,
    });
    const data = handleApiResult(result);
    setLoading(false);
    if (result.data) {
      localStorage.setItem("authToken", data.token);
      localStorage.setItem("userEmail", data.email);
      localStorage.setItem("userId", data.id);

      setIsLoggedIn(true);
      navigate("/");
    }
  };

  return (
    <div>
      <Alert errorMessage={errorMessage} successMessage={successMessage} />
      <div className="flex p-4 justify-center">
        <form className="form-floating w-75" onSubmit={handleSubmit}>
          <div className="box w-100">
            <h1 className="mb-3 flex justify-center">CSM-Dashboard</h1>

            <div className="form-floating mb-3 md-6 w-100">
              <input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="email"
                className="form-control form-control-lg"
                id="floatingInput"
                placeholder="name@example.com"
              />
              <label htmlFor="floatingInput">Email address</label>
            </div>
            <div className="form-floating w-100">
              <input
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                type="password"
                className="form-control"
                id="floatingPassword"
                placeholder="Password"
              />
              <label htmlFor="floatingPassword">Password</label>
            </div>
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4"
            >
              Sign in
              {loading && <LoaderCircle />}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
